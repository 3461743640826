export const listReason = [
    {
        title: 'ROYALTIES',
        description: 'For life. Even after you retire',
    },
    {
        title: 'REDUCED PIRACY',
        description: 'Digital animation wrappers',
    },
    {
        title: 'INNOVATIVE MONETIZATION',
        description: 'Made possible by NFT technology',
    },
    {
        title: 'BY CREATORS, FOR CREATORS',
        description: 'Deep industry experience',
    },
    {
        title: 'PAID IN FIAT',
        description: 'No shilling or currency swapping',
    },
    {
        title: 'MINIMAL TIME INVESTMENT',
        description: 'We make your workflow efficient',
    },
];
