/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/function-component-definition */
import React from 'react';

type DataContextInterface = {
    chainId: number;
    setChainId: React.Dispatch<React.SetStateAction<number>>;
    user: UserInterface | null;
    setUser: React.Dispatch<React.SetStateAction<UserInterface | null>>;
};
type UserInterface = {
    address: string;
    token: string;
    user: any;
};
type DataContextProviderProps = {
    children?: React.ReactNode;
};

export const DataContext = React.createContext<DataContextInterface | null>(null);

export const DataContextProvider: React.FC = ({ children }: DataContextProviderProps) => {
    const [chainId, setChainId] = React.useState(1);
    const [user, setUser] = React.useState<UserInterface | null>(null);
    return <DataContext.Provider value={{ chainId, setChainId, user, setUser }}>{children}</DataContext.Provider>;
};
