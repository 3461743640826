import React from 'react';
import './styles.scss';
import { onMoveAnimation } from 'src/services/hooks/useDevelopUI';
import IconHotDrop from '../../../../assets/images/home/icon-hotdrop.png';

export function CreateAccount() {
    return (
        <div className="create-account-container">
            <div className="create-account-wrapper">
                <div className="create-account-wrapper__content">
                    <div className="icon">
                        <img src={IconHotDrop} alt="iconHotDrop" className="content" />
                    </div>
                    <div className="title">GET THE HOTTEST VIP BENEFITS</div>
                    <div
                        onClick={() => onMoveAnimation('authenticate-form', 'moveInOpacity', 'div.authenticate__form > span.switch-tab > span')}
                        className="btn-create-account"
                    >
                        CREATE ACCOUNT
                    </div>
                </div>
            </div>
        </div>
    );
}
