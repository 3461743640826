import React from 'react';
import './styles.scss';
import NextGenImage from '../../../../assets/images/home/1_Collect Next Generation Content.png';
import SupFavor from '../../../../assets/images/home/1_Support Favorite Creators.png';
import UnExclusive from '../../../../assets/images/home/1_Unlock Exclusive Rewards.png';

export function Feature() {
    return (
        <div className="feature-container">
            <div className="feature-wrapper">
                <div className="feature-item">
                    <img src={SupFavor} alt="Support Favorite" />
                    <span className="featureName">Support</span>
                    <span className="content">Favorite Creators</span>
                </div>
                <div className="feature-item">
                    <img src={NextGenImage} alt="Netx Generation" />
                    <p className="featureName">COLLECT</p>
                    <span className="content">Next Generation Content</span>
                </div>
                <div className="feature-item">
                    <img src={UnExclusive} alt="Unlock Exclusive" />
                    <p className="featureName">UNLOCK</p>
                    <span className="content">Exclusive Rewards</span>
                </div>
            </div>
        </div>
    );
}
