export const ERROR_CODE: { [errorId: string]: number } = {
    NONE: 0,
    AUTHENTICATION_ERROR: 1,
    INVALID_ADDRESS: 7,
    SIGNATURE_INVALID: 8,
    SERVER_ERROR: 3,
    INVALID_OWNER_ADDRESS: 9,
    COULD_NOT_READ_CONTRACT: 10,
    CONFIG_NOT_AUTHORIZED: 11,

    INVALID_USERNAME_PASSWORD: 12,

    ALREADY_REGISTER_USERNAME: 13,
    PLAYER_TOKEN_NOT_MATCH: 14,
    INVALID_USERNAME: 15,
    INVALID_PASSWORD: 16,
    INVALID_EMAIL: 17,
    USERNAME_HAS_REGISTERED: 18,
    EMAIL_HAS_REGISTERED: 19,
    INVALID_WALLET_ADDRESS: 20,
    WALLET_HAS_USED: 21,
    WALLET_ALREADY_LINK: 22,
    USER_NOT_FOUND: 23,
    INPUT_EMPTY: 24,

    // CONTACT
    REQUIRE_EMAIL: 30,
    REQUIRE_INSTAGRAM_OR_TWITTER: 31,
};
