import { baseRequest } from '../base-request';

export default {
    login: async (credentials: any) => {
        let url = '/api/v1/auth/login';
        const res = await baseRequest('post', url, credentials);
        return res;
    },
    sendOtp: async (data: any) => {
        let url = '/api/v1/auth/send-otp';
        const res = await baseRequest('post', url, data);
        return res;
    },
    register: async (credentials: any) => {
        let url = '/api/v1/auth/register';
        const res = await baseRequest('post', url, credentials);
        return res;
    },
    forgotPassword: async (data: any) => {
        let url = '/api/v1/auth/forgot-password';
        const res = await baseRequest('post', url, data);
        return res;
    },
    validateEmailToken: async (data: any) => {
        let url = '/api/v1/auth/validation-email-token';
        const res = await baseRequest('post', url, data);
        return res;
    },
    resetPassword: async (data: any) => {
        let url = '/api/v1/auth/reset-password';
        const res = await baseRequest('post', url, data);
        return res;
    },
}
