export const menuItemDesktop = [
    {
        name: 'Marketplace',
        path: '/marketplace',
        subMenu: undefined,
    },
    {
        name: 'Creators',
        path: '/creators',
        subMenu: undefined,
    },
    {
        name: 'How it works',
        path: '/how-it-works',
        subMenu: undefined,
    },
];
