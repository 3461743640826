import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppButton } from 'src/components/Base';
import { RootState } from '../../../app/store';
import { loginSchema } from '../validate-schema';
import { login } from '../slice';
import ForgotPassword from '../forgot-password';

export default function LoginForm(props: any) {
    const { onClose, toggleRegister } = props;
    const dispatch = useDispatch();
    const authSelector = useSelector((state: RootState) => state.auth);
    const [defaultValues] = useState({
        email: '',
        password: '',
    });
    const [valueEmail, setValueEmail] = useState('');
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues,
        resolver: yupResolver(loginSchema),
    });
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false);

    const handleForgotPasswordModalOpen = () => {
        setForgotPasswordModal(true);
    };

    const handleForgotPasswordModalClose = () => {
        setForgotPasswordModal(false);
    };

    const onClosePopup = () => {
        onClose();
        reset(defaultValues);
        setValueEmail('');
    };

    const onLoginSubmit = (data: any) => {
        try {
            dispatch(login({ data, onClose: onClosePopup, onSuccess: () => null }));
        } catch (err: any) {
            toast.error(err?.message, {
                position: 'top-center',
            });
        }
    };

    return (
        <>
            <div onClick={onClosePopup} className="overlay" />
            <div className="authenticate__form">
                <CloseIcon onClick={onClosePopup} />
                <span className="title">Sign In</span>
                {/* <div className="authenticate__form--thirdparty">
                    <FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_ID || ''}
                        fields="name,email,picture"
                        onClick={() => console.log('clicked')}
                        callback={(response: any) => onLoginFacebook(response)}
                        textButton="Continue with Facebook"
                        icon="fa-facebook"
                    />
                    <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_ID || ''}
                        buttonText="Continue with Google"
                        onSuccess={(response) => onLoginGoogle(response)}
                        onFailure={(response) => toast.error(response?.error)}
                        cookiePolicy="single_host_origin"
                    />
                </div> */}
                <form onSubmit={handleSubmit(onLoginSubmit)} className="form-container">
                    {/* <div className="border" />
                    <div className="border-text">or</div> */}
                    <div className="form-item">
                        <div className="label">
                            <div className="label-name">Email</div>
                            <div className="label-error">{errors?.email?.message}</div>
                        </div>
                        <div className="input-field">
                            <input
                                {...register('email', {
                                    onChange: (e) => setValueEmail(e.target.value.replace(/\s/g, '')),
                                })}
                                type="text"
                                value={valueEmail}
                                maxLength={100}
                            />
                            {errors?.email && <HighlightOffIcon className="input-error-suffix" />}
                        </div>
                    </div>
                    <div className="form-item">
                        <div className="label">
                            <div className="label-name">Password</div>
                            <div className="label-error">{errors?.password?.message}</div>
                        </div>
                        <div className="input-field">
                            <input {...register('password')} type="password" maxLength={16} />
                            {errors?.password && <HighlightOffIcon className="input-error-suffix" />}
                        </div>
                    </div>
                    <div className="forgot-password" onClick={handleForgotPasswordModalOpen}>
                        Forgot Password?
                    </div>
                    <AppButton
                        className="btn-submit"
                        type="submit"
                        disabled={authSelector?.isLoading}
                        loading={authSelector?.isLoading}
                    >
                        Sign In
                    </AppButton>
                </form>
                <span className="switch-tab">
                    Don&apos;t have an account? <span onClick={toggleRegister}>Sign Up</span>
                </span>
            </div>
            <ForgotPassword visible={forgotPasswordModal} onClose={handleForgotPasswordModalClose} />
        </>
    );
}
