export const DropList = [
    {
        price: '140.00',
        image: 'drops_1',
    },
    {
        price: '40.00',
        image: 'drops_2',
    },
    {
        price: '80.00',
        image: 'drops_3',
    },
    {
        price: '60.00',
        image: 'drops_4',
    },
    {
        price: '140.00',
        image: 'drops_1',
    },
    {
        price: '40.00',
        image: 'drops_2',
    },
    {
        price: '80.00',
        image: 'drops_3',
    },
    {
        price: '60.00',
        image: 'drops_4',
    },
];
