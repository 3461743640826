import { baseRequest } from '../base-request';

export default {
    getUserProfile: async () => {
        let url = '/api/v1/auth/me';
        const res = await baseRequest('get', url);
        return res;
    },
    saveUserWallet: async (data: any) => {
        let url = '/api/v1/users/save-wallet';
        const res = await baseRequest('post', url, data);
        return res;
    },
    getUserFavorite: async () => {
        let url = '/api/v1/users/favorite';
        const res = await baseRequest('get', url);
        return res;
    },
    getUserSubscribe: async () => {
        let url = '/api/v1/users/subscribe';
        const res = await baseRequest('get', url);
        return res;
    },
};
