import React, { ReactNode } from 'react';
import { Footer } from 'src/components/Footer';
import { Header } from 'src/components/Header';

function DefaultLayout({ children }: { children: ReactNode }) {
    return (
        <>
            <Header />
            {children}
            <Footer />
        </>
    );
}

export default DefaultLayout;
