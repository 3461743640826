import React from 'react';
import './styles.scss';
import { GetAccessPassStatus } from 'src/types/status';
import eapSuccessImg from 'src/assets/images/eap-success.png';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
    getAccessPassStatus: GetAccessPassStatus;
    onCloseModal: () => void;
};

export function ProcessGetAccessPassModal({ getAccessPassStatus, onCloseModal }: Props) {
    return (
        <div className="process-get-access-pass-modal">
            <div className="content">
                {getAccessPassStatus === 'pending' ? (
                    <>
                        <div className="loading" />
                        <h2>Processing Your Order</h2>
                        <p>Hang tight for a few seconds. Do not refresh the page.</p>
                    </>
                ) : getAccessPassStatus === 'success' ? (
                    <>
                        <img src={eapSuccessImg} alt="eap-success-img" />
                        <h2>Your order is complete!</h2>
                        <p>You have become a VIP Member! Please check your status in the profile page.</p>
                        <button type="button" className="btn-done" onClick={onCloseModal}>
                            Done
                        </button>
                        <button type="button" className="btn-close">
                            <CloseIcon onClick={onCloseModal} />
                        </button>
                    </>
                ) : null}
            </div>
        </div>
    );
}
