import React from 'react';
import './styles.scss';
import { AccessTime } from '@material-ui/icons';
import { HottestCard } from './HottestCard/index';
import { HottestList } from './hottest-list';

export function CollectHottest() {
    return (
        <div className="wrapper">
            <div className="hottest-container">
                <div className="hottest-container__title">
                    <span className="hottest-container__title top">
                        COLLECT <br />
                    </span>
                    <span className="hottest-container__title bottom">THE HOTTEST DROPS </span>
                </div>
                <div className="hottest-container__list-card">
                    {HottestList.map((item, key) => (
                        <HottestCard
                            name={item.name}
                            price={item.price}
                            image={item.image}
                            icon={item.icon}
                            key={key.toString() + item}
                        />
                    ))}
                </div>
                <div className="hottest-container__commingsoon">
                    <AccessTime className="icon-commingsoon" />
                    MARKETPLACE COMING SOON
                </div>
            </div>
        </div>
    );
}
