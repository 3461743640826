import { useEffect, useMemo, useState } from 'react';
import { Contract, ContractInterface } from '@ethersproject/contracts';
import { Web3Provider } from '@ethersproject/providers';
import { useWallet } from 'use-wallet';
import { ethers } from 'ethers';
import { DEFAULT_CHAIN_ID } from 'src/constants/chainId';
import { CHAIN_SUPPORTED, FULL_NODE_ADDRESS } from 'src/constants/constant';
import { getContract } from '../utils/getContract';

export const useChain = (): number => {
    let { chainId } = useWallet();
    if (!chainId) return DEFAULT_CHAIN_ID;
    if (CHAIN_SUPPORTED.includes(chainId)) {
        return chainId;
    }
    return DEFAULT_CHAIN_ID;
}

const useContract = (address: string, ABI: ContractInterface) => {
    const { account, chainId, connector, ethereum } = useWallet();
    const [contract, setContract] = useState<Contract>();

    const initContract = async () => {
        if (!account || !chainId || (connector !== 'walletconnect' && !window.ethereum)) {
            return;
        }

        const provider = connector === 'walletconnect' ? new Web3Provider(ethereum) : new Web3Provider(window.ethereum);
        const signer = provider && (await provider.getSigner());
        const sc = new Contract(address, ABI, signer || provider);

        setContract(sc);
    };

    useEffect(() => {
        initContract();
    }, [account, chainId]);

    return contract;
};

export default useContract;

export function useContractWithProvider<T extends Contract = Contract>(
    addressOrAddressMap: string,
    ABI: ContractInterface,
): T | null {
    let { account } = useWallet();
    const chainId = useChain();
    const provider = ethers.getDefaultProvider(FULL_NODE_ADDRESS[chainId]);
    return useMemo(() => {
        if (!provider) return null;
        if (!addressOrAddressMap || !ABI) return null;
        let address: string | undefined;
        if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap;
        else address = addressOrAddressMap[chainId];
        if (!address) return null;
        try {
            return getContract(addressOrAddressMap, ABI, provider, account || undefined);
        } catch (error) {
            console.error('Failed to get contract', error);
            return null;
        }
    }, [provider, addressOrAddressMap, ABI, chainId, account]) as T;
}
