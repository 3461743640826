/**
 *
 * Asynchronously loads the component
 *
 */

import { lazyLoad } from '../../services/utils/loadable';

export const ComingPage = lazyLoad(
    () => import('./index'),
    (module) => module.Coming,
);
