import React from 'react';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { listReason } from './hard';
import { ReasonItem } from './ReasonItem/index';
import { postContact } from '../../slice';

export function CreatorPage() {
    const [info, setInfo] = React.useState({
        email: '',
        web: '',
        onlyfan: '',
    });
    const dispatch = useDispatch();
    return (
        <div className="bg-creator">
            <div className="creator-container">
                <div className="creator-container__left">
                    <div className="creator-container__left__wrapper">
                        <div className="left-title">
                            Why Creators{' '}
                            <span className="love">
                                Love <span />
                            </span>
                            Hot Drops
                        </div>
                        <hr className="hr-title" />
                        <div className="left-body">
                            {listReason.map((item, key) => (
                                <ReasonItem
                                    title={item.title}
                                    description={item.description}
                                    key={key.toString() + item}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="creator-container__right">
                    <div className="creator-container__right__inline" />
                    <div className="creator-container__right__outline">
                        <span className="creator-container__right__outline__title">Are you a creator?</span>
                        <div className="creator-container__right__outline__detail">
                            Be one of the first creators on Hot Drops and get lifetime royalties for your content
                        </div>
                        <div className="creator-container__right__outline__input-list">
                            <input
                                value={info.web}
                                onChange={(e) => setInfo({ ...info, web: e.target.value })}
                                type="text"
                                placeholder="INSTAGRAM OR TWITTER HANDLE"
                                className="creator-input"
                            />
                            <input
                                value={info.email}
                                onChange={(e) => setInfo({ ...info, email: e.target.value })}
                                type="text"
                                placeholder="EMAIL ADDRESS"
                                className="creator-input"
                            />
                            <input
                                value={info.onlyfan}
                                onChange={(e) => setInfo({ ...info, onlyfan: e.target.value })}
                                type="text"
                                placeholder="ONLY FANS ACCOUNT URL (optional)"
                                className="creator-input"
                            />
                        </div>
                        <div
                            onClick={() => dispatch(postContact({ info, setInfo }))}
                            className="creator-container__right__outline__btn-getintouch"
                        >
                            GET IN TOUCH
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
