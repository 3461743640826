/* eslint-disable @typescript-eslint/no-unused-vars */
import { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'src/app/store';
import { EAP_TOKEN_ADDRESS, HOT_DROPS_CONTRACT } from 'src/constants/addresses';
import HOTDROPS_ABI from 'src/constants/abis/hotdrops-abi.json';
import ERC20_ABI from 'src/constants/abis/erc20-abi.json';
import { toast } from 'react-toastify';
import { Contract } from '@ethersproject/contracts';
import { getContract } from 'src/services/utils/getContract';
import { DEFAULT_CHAIN_ID } from 'src/constants/chainId';
import { constants } from 'ethers';
import { Web3Provider } from '@ethersproject/providers';
import { formatBalance } from 'src/services/utils/format';
import { transactionActions } from '../transaction/reducer';
import { ApproveStatus } from '../types';

const cancelTx = (store: MiddlewareAPI<AppDispatch, RootState>) => {
    store.dispatch(transactionActions.cancelCallback());
    store.dispatch(transactionActions.isPendingTransaction({ isPending: false }));
};

const middlewaresClosure = (): Middleware => {
    let hotdropsContract: Contract;
    let walletData = {
        library: undefined,
        account: '',
        chainId: DEFAULT_CHAIN_ID,
    };

    return (store: MiddlewareAPI<AppDispatch, RootState>) => (next) => async (action: any) => {
        switch (action.type) {
            case transactionActions.masterDataWallet.type: {
                const { chainId, provider, account } = action.payload;
                if (chainId !== DEFAULT_CHAIN_ID) return;
                const web3Provider = new Web3Provider(provider);
                const signer = web3Provider && (await web3Provider.getSigner());
                walletData = {
                    library: signer as any,
                    account,
                    chainId,
                };
                hotdropsContract = getContract(HOT_DROPS_CONTRACT[chainId], HOTDROPS_ABI, signer);
                if (!hotdropsContract) return;
                const totalOrderBadges = await hotdropsContract.totalOrderBadges();
                const currentEAP = totalOrderBadges - 1;
                const orderBadges = await hotdropsContract.orderBadges(currentEAP);
                next({
                    ...action,
                    payload: {
                        ...action.payload,
                        orderBadgesCurrent: {
                            orderId: currentEAP,
                            tokenId: Number(orderBadges.tokenId),
                            price: formatBalance(orderBadges.price),
                            owner: orderBadges.owner,
                        },
                    },
                });
                break;
            }
            case transactionActions.handleBuyNft.type: {
                (async () => {
                    try {
                        store.dispatch(transactionActions.startTx());
                        if (!hotdropsContract || !walletData.account) return;
                        const { orderIndex, quantity, disCode } = action.payload;
                        const params = [orderIndex, quantity, disCode];
                        const gasLimit = await hotdropsContract.estimateGas.buyNFT(...params);
                        const tx = await hotdropsContract.buyNFT(...params, { gasLimit });
                        const txWaited = await tx.wait();

                        if (txWaited?.status === 1) {
                            toast.success('Buy NFT Successfully!', {
                                position: 'top-center',
                            });
                            next({
                                ...action,
                                payload: {
                                    ...action.payload,
                                },
                            });
                        } else {
                            cancelTx(store);
                            
                        }
                    } catch (error: any) {
                        switch (error?.code) {
                            case 4001:
                                toast.error(String(error.message), {
                                    position: 'top-center',
                                });
                                break;
                            default:
                                toast.error('Transaction Failed!', {
                                    position: 'top-center',
                                });
                                break;
                        }
                        cancelTx(store);
                        console.log({error})
                    }
                })();

                break;
            }

            case transactionActions.handleBuyBadges.type: {
                (async () => {
                    try {
                        if (!hotdropsContract || !walletData.account) return;

                        const { orderIndex, quantity, callback } = action.payload;
                        store.dispatch(transactionActions.startTx());
                        const params = [orderIndex, quantity];
                        const gasLimit = await hotdropsContract.estimateGas.buyBadges(...params);

                        const tx = await hotdropsContract.buyBadges(...params, { gasLimit });
                        const txWaited = await tx.wait();

                        if (txWaited?.status === 1) {
                            toast.success('Buy Badges Successfully!', {
                                position: 'top-center',
                            });

                            next({
                                ...action,
                                payload: {
                                    ...action.payload,
                                    callback: callback("success"),
                                }
                            });
                        } else {
                            toast.error('Buy Badges Unsuccessfully!', {
                                position: 'top-center',
                            });
                            next({
                                ...action,
                                payload: {
                                    ...action.payload,
                                    callback: callback("idle"),
                                },
                            });
                        }

                        return;
                    } catch (err: any) {
                        store.dispatch(transactionActions.isPendingTransaction({ isPending: false }));
                        next({
                            ...action,
                            payload: {
                                ...action.payload,
                                callback: action.payload.callback("error"),
                            },
                        });
                        toast.error(err?.error?.data?.message, {
                            position: 'top-center',
                        });
                    }
                })();

                break;
            }

            case transactionActions.approveToken.type: {
                (async () => {
                    try {
                        const { spender, value, token } = action.payload;
                        if (!spender || !token || !walletData.account) return;
                        const tokenContract = getContract(token, ERC20_ABI, walletData.library);
                        if (!tokenContract) return;
                        store.dispatch(transactionActions.startTx());
                        store.dispatch(transactionActions.updateApproveStatus({ approveStatus: 'Approving', token }));

                        const params = [spender, constants.MaxUint256];
                        const gasLimit = await tokenContract.estimateGas.approve(...params);

                        const tx = await tokenContract.approve(...params, { gasLimit });
                        const txWaited = await tx.wait();

                        if (txWaited?.status === 1) {
                            toast.success('Approve Successfully!', {
                                position: 'top-center',
                            });
                            next({
                                ...action,
                                payload: {
                                    ...action.payload,
                                    approveStatus: 'Approved' as ApproveStatus,
                                },
                            });
                        } else {
                            toast.error('Approve Unsuccessfully!', {
                                position: 'top-center',
                            });
                            store.dispatch(transactionActions.isPendingTransaction({ isPending: false }));
                            next({
                                ...action,
                                payload: {
                                    ...action.payload,
                                    approveStatus: 'Not Approved' as ApproveStatus,
                                    token: '',
                                    callback: undefined,
                                },
                            });
                        }
                    } catch ({ message }) {
                        toast.error(String(message), {
                            position: 'top-center',
                        });
                        store.dispatch(transactionActions.isPendingTransaction({ isPending: false }));
                        next({
                            ...action,
                            payload: {
                                ...action.payload,
                                approveStatus: 'Not Approved' as ApproveStatus,
                                token: '',
                                callback: undefined,
                            },
                        });
                    }
                })();
                break;
            }
            default:
                next(action);
                break;
        }
    };
};

export const netMiddleware = middlewaresClosure();
