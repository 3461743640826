import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { forgotPasswordSchema, otpSchema, resetPasswordSchema } from '../validate-schema';
import authApi from '../../../api/auth';
import Countdown from '../register-form/countdown';

export default function ForgotPassword(props: any) {
    const { visible, onClose } = props;
    const [formStep, setFormStep] = useState(1);
    const [emailForgot, setEmailForgot] = useState('');
    const [codeOtp, setCodeOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [codeExpireTime, setCodeExpireTime] = useState<any>();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(forgotPasswordSchema),
    });

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        formState: { errors: errors2 },
        reset: reset2,
    } = useForm({
        resolver: yupResolver(otpSchema),
    });

    const {
        register: register3,
        handleSubmit: handleSubmit3,
        formState: { errors: errors3 },
        reset: reset3,
    } = useForm({
        resolver: yupResolver(resetPasswordSchema),
    });

    const handleCloseModal = () => {
        onClose();
        setFormStep(1);
        setIsLoading(false);
        reset({ email: '' });
        reset2({ code: '' });
        reset3({
            newPassword: '',
            confirmPassword: '',
        })
    }

    const onSubmitEmailForgot = async (data: any) => {
        try {
            setIsLoading(true);
            let emailInput = {
                email: data?.email,
            }
            setEmailForgot(data?.email);
            const res = await authApi.forgotPassword(emailInput);
            if (res?.status === 201) {
                setIsLoading(false);
                setFormStep(2);
                setCodeExpireTime(moment().add(1, 'm'));
            }
            else if (res?.status === 401) {
                setIsLoading(false);
                toast.error(res?.data?.message, {
                    position: 'top-center',
                });
            }
            else if (res?.status === 403) {
                setIsLoading(false);
                toast.error(res?.data?.message, {
                    position: 'top-center',
                });
            }
            else {
                setIsLoading(false);
                toast.error('Something went wrong', {
                    position: 'top-center',
                });
            }
        }
        catch (err: any) {
            setIsLoading(false);
            toast.error(err?.message, {
                position: 'top-center',
            });
        }
    }

    const resendOtp = async () => {
        try {
            let emailInput = {
                email: emailForgot,
            }
            const res = await authApi.forgotPassword(emailInput);
            if (res?.status === 201) {
                toast.success('New code has been sent to your email', {
                    position: 'top-center',
                });
                setCodeExpireTime(moment().add(1, 'm'));
            }
            else if (res?.status === 403) {
                toast.error(res?.data?.message, {
                    position: 'top-center',
                });
            }
            else {
                setIsLoading(false);
                toast.error('Something went wrong', {
                    position: 'top-center',
                });
            }
        }
        catch (err: any) {
            toast.error(err?.message, {
                position: 'top-center',
            });
        }
    }

    const onSubmitCodeOtp = async (data: any) => {
        try {
            setIsLoading(true);
            let otpInput = {
                email: emailForgot,
                token: data?.code,
            }
            setCodeOtp(data?.code);
            const res = await authApi.validateEmailToken(otpInput);
            if (res?.status === 201) {
                setIsLoading(false);
                setFormStep(3);
            }
            else if (res?.status === 400) {
                setIsLoading(false);
                toast.error('Incorrect code. Please try again', {
                    position: 'top-center',
                });
            }
            else {
                setIsLoading(false);
                toast.error('Something went wrong', {
                    position: 'top-center',
                });
            }

        }
        catch (err: any) {
            setIsLoading(false);
            toast.error(err?.message, {
                position: 'top-center',
            });
        }
    }

    const onSubmitNewPassword = async (data: any) => {
        try {
            setIsLoading(true);
            let resetInput = {
                email: emailForgot,
                token: codeOtp,
                password: data?.newPassword,
                passwordConfirmation: data?.confirmPassword,
            }
            const res = await authApi.resetPassword(resetInput);
            if (res?.status === 201) {
                setIsLoading(false);
                toast.success('Your password has been reset!', {
                    position: 'top-center',
                });
                handleCloseModal();
            }
            else if (res?.status === 400) {
                setIsLoading(false);
                toast.error(res?.data?.message, {
                    position: 'top-center',
                });
            }
            else {
                setIsLoading(false);
                toast.error('Something went wrong', {
                    position: 'top-center',
                });
            }
        }
        catch (err: any) {
            setIsLoading(false);
            toast.error(err?.message, {
                position: 'top-center',
            });
        }
    }

    const renderFormStep1 = () => (
        <div className="verify-modal">
            <KeyboardArrowLeftIcon className="back-icon" onClick={handleCloseModal} />
            <form onSubmit={handleSubmit(onSubmitEmailForgot)} className="form-container" key={1}>
                <div className="verify-modal-top">
                    <div className="verify-modal-top__title">Forgot Password</div>
                    <div className="verify-modal-top__subtitle">Enter the email address associated with your account</div>
                </div>
                <div className="verify-modal-body">
                    <div className="verify-modal-body__label">
                        <div className="label-name">Email</div>
                        <div className="label-error">{errors?.email?.message}</div>
                    </div>
                    <div className="verify-modal-body__input">
                        <input
                            {...register('email', {
                                onChange: (e) => setEmailForgot(e.target.value.replace(/\s/g, ''))
                            })}
                            type="text"
                            value={emailForgot}
                            maxLength={100}
                        />
                        {errors?.email && <HighlightOffIcon className="input-error-suffix" />}
                    </div>
                </div>
                <div className="verify-modal-bottom">
                    <button className="btn-submit" type="submit" disabled={isLoading}>
                        {isLoading ? <CircularProgress /> : 'Next'}
                    </button>
                </div>
            </form>
        </div>
    )

    const renderFormStep2 = () => (
        <div className="verify-modal">
            <KeyboardArrowLeftIcon className="back-icon" onClick={() => setFormStep(1)} />
            <form onSubmit={handleSubmit2(onSubmitCodeOtp)} className="form-container" key={2}>
                <div className="verify-modal-top">
                    <div className="verify-modal-top__title">Verify Email</div>
                    <div className="verify-modal-top__subtitle">Enter the code we’ve sent to your email:</div>
                    <div className="verify-modal-top__subtitle">{emailForgot}</div>
                </div>
                <div className="verify-modal-body">
                    <div className="verify-modal-body__label">
                        <div className="label-name">Code</div>
                        <div className="label-error">{errors2?.code?.message}</div>
                    </div>
                    <div className="verify-modal-body__input">
                        <input {...register2('code')} type="text" />
                        {errors2?.code && <HighlightOffIcon className="input-error-suffix" />}
                    </div>
                    <Countdown eventTime={codeExpireTime} resendOtp={resendOtp} />
                </div>
                <div className="verify-modal-bottom">
                    <button className="btn-submit" type="submit" disabled={isLoading}>
                        {isLoading ? <CircularProgress /> : 'Next'}
                    </button>
                </div>
            </form>
        </div>
    )

    const renderFormStep3 = () => (
        <div className="verify-modal">
            <KeyboardArrowLeftIcon className="back-icon" onClick={() => setFormStep(2)} />
            <form onSubmit={handleSubmit3(onSubmitNewPassword)} className="form-container" key={3}>
                <div className="verify-modal-top">
                    <div className="verify-modal-top__title">Create New Password</div>
                    <div className="verify-modal-top__subtitle">Create a new password to sign in to your account</div>
                </div>
                <div className="verify-modal-body">
                    <div className="form-item">
                        <div className="verify-modal-body__label">
                            <div className="label-name">New Password</div>
                            <div className="label-error">{errors3?.newPassword?.message}</div>
                        </div>
                        <div className="verify-modal-body__input">
                            <input {...register3('newPassword')} type="password" maxLength={16} />
                            {errors3?.newPassword && <HighlightOffIcon className="input-error-suffix" />}
                        </div>
                    </div>
                    <div className="form-item">
                        <div className="verify-modal-body__label">
                            <div className="label-name">Confirm New Password</div>
                            <div className="label-error">{errors3?.confirmPassword?.message}</div>
                        </div>
                        <div className="verify-modal-body__input">
                            <input {...register3('confirmPassword')} type="password" maxLength={16} />
                            {errors3?.confirmPassword && <HighlightOffIcon className="input-error-suffix" />}
                        </div>
                    </div>
                </div>
                <div className="verify-modal-bottom">
                    <button className="btn-submit" type="submit" disabled={isLoading}>
                        {isLoading ? <CircularProgress /> : 'Next'}
                    </button>
                </div>
            </form>
        </div>
    )

    return (
        <Modal
            open={visible}
            onClose={onClose}
            BackdropProps={{
                style: {
                    backgroundColor: 'rgba(0, 0, 0, 1)',
                },
            }}
        >
            <>
                {formStep === 1 && renderFormStep1()}
                {formStep === 2 && renderFormStep2()}
                {formStep === 3 && renderFormStep3()}
            </>
        </Modal>
    )
}
