import { baseRequest } from "../base-request";
import { IDataTrending, IResponseSearch } from "../type";

export default {
    handleSearch: async (query?: string): Promise<IResponseSearch> => {
        const url = query ? `/api/v1/creator/search?query=${query}` : '/api/v1/creator/search';
        const {
            data: { data: res },
        } = await baseRequest('get', url);
        return res;
    },
    searchTrending: async (data: IDataTrending) => {
        const url = '/api/v1/stat/trending';
        const {
            data: { data: res },
        } = await baseRequest('post', url, data);
        return res;
    }
}
