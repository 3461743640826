/* eslint-disable radix */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { toast } from 'react-toastify';
import { DataContext } from 'src/context/DataContext';
import { useWallet } from 'use-wallet';
import { ERROR_CODE } from 'src/constants/error_code';
import { useQuery } from 'react-query';
import { useDisconnectWallet } from './useWalletProviders';

const handleVerify = (signature: string, publicAddress: string | null, callback: any) => {
    return fetch(`${process.env.REACT_APP_API}/api/v1/login/verify/${publicAddress}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
            signature,
        }),
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.error_code === 0) {
                let user_key = JSON.parse(localStorage.getItem('user_key') || '[]');
                const index = user_key.findIndex((element: any) => element.address === res.data.address);
                if (index === -1) {
                    user_key.push(res.data);
                } else {
                    user_key[index] = res.data;
                }
                localStorage.setItem('user_key', JSON.stringify(user_key));
                callback(res?.data);
                toast.success('Verify successful');
            } else {
                toast.error('Verify failed');
            }
        });
};

const requestSignature = async (publicAddress: string | null, challenge: string, callback: any, connector: any) => {
    const msgParams = challenge;

    try {
        const result = await connector?.send('personal_sign', [msgParams, publicAddress]);
        handleVerify(result.result, publicAddress, callback);
    } catch (err: any) {
        toast.error(err.message);
    }
};

export const useHandleSignIn = () => {
    const data = React.useContext(DataContext);
    const wallet = useWallet();
    return () => {
        const publicAddress = wallet.account;
        fetch(`${process.env.REACT_APP_API}/api/v1/login/challenge/${publicAddress}`)
            .then((response) => response.json())
            // If yes, retrieve it. If no, create it.
            .then((res) => requestSignature(publicAddress, res.data.msg, data?.setUser, wallet.ethereum));
    };
};

export const useAuthenticate = () => {
    const data = React.useContext(DataContext);
    React.useEffect(() => {
        const account = JSON.parse(localStorage.getItem('persist:account') || 'null');
        if (account) {
            data?.setUser(account);
        }
    }, []);
};

export const useVerrifyAccount = () => {
    const data = React.useContext(DataContext);
    return (result: any) => {
        if (parseInt(result.error_code) === 1) {
            localStorage.removeItem('user_key');
            data?.setUser(null);
            toast.error('Authenticate Error, Try login again.');
        }
    };
};

export const useRegister = (setError: (value: string) => void, onDone: () => void) => {
    const onVerifyKey = useVerrifyAccount();
    return async (info: any) => {
        if (info.password && info.username) {
            if (info?.password) {
                fetch(`${process.env.REACT_APP_API}/api/v1/user/register`, {
                    method: 'POST',
                    body: JSON.stringify({
                        email: info.email,
                        username: info.username,
                        password: info.password,
                    }),
                    headers: {
                        'Content-type': 'application/json',
                    },
                })
                    .then((res) => res.json())
                    .then((result) => {
                        if (result.error_code === 0) {
                            setError('');
                            toast.success('Register successful');
                            onDone();
                        } else {
                            for (let attr in ERROR_CODE) {
                                if (
                                    ERROR_CODE[attr] === parseInt(result.error_code) &&
                                    parseInt(result.error_code) !== 1
                                ) {
                                    setError(attr);
                                    toast.error(attr);
                                }
                            }
                            onVerifyKey(result);
                        }
                    });
            }
        } else {
            setError('Fill inputs.');
        }
    };
};

export const useLogin = (setError: (value: string) => void, onDone: () => void) => {
    const onVerifyKey = useVerrifyAccount();
    const data = React.useContext(DataContext);
    return async (info: any) => {
        if (info.password && info.username) {
            if (info?.password) {
                fetch(`${process.env.REACT_APP_API}/api/v1/login/login_user`, {
                    method: 'POST',
                    body: JSON.stringify({
                        username: info.username,
                        password: info.password,
                    }),
                    headers: {
                        'Content-type': 'application/json',
                    },
                })
                    .then((res) => res.json())
                    .then((result) => {
                        if (result.error_code === 0) {
                            localStorage.setItem('persist:account', JSON.stringify(result.data));
                            data?.setUser(result.data);
                            setError('');
                            toast.success('Login successful');
                            onDone();
                        } else {
                            for (let attr in ERROR_CODE) {
                                if (
                                    ERROR_CODE[attr] === parseInt(result.error_code) &&
                                    parseInt(result.error_code) !== 1
                                ) {
                                    setError(attr);
                                    toast.error(attr);
                                }
                            }
                            onVerifyKey(result);
                        }
                    });
            }
        } else {
            setError('Fill inputs.');
        }
    };
};

export const useLoginFacebook = (setError: (value: string) => void, onDone: () => void) => {
    const onVerifyKey = useVerrifyAccount();
    const data = React.useContext(DataContext);
    return async (info: any) => {
        if (!info?.error) {
            fetch(`${process.env.REACT_APP_API}/api/v1/login/login_facebook`, {
                method: 'POST',
                body: JSON.stringify({
                    accessToken: info?.accessToken,
                }),
                headers: {
                    'Content-type': 'application/json',
                },
            })
                .then((res) => res.json())
                .then((result) => {
                    if (result.error_code === 0) {
                        localStorage.setItem('persist:account', JSON.stringify(result.data));
                        data?.setUser(result.data);
                        setError('');
                        toast.success('Login successful');
                        onDone();
                    } else {
                        for (let attr in ERROR_CODE) {
                            if (ERROR_CODE[attr] === parseInt(result.error_code) && parseInt(result.error_code) !== 1) {
                                setError(attr);
                                toast.error(attr);
                            }
                        }
                        onVerifyKey(result);
                    }
                });
        } else {
            toast.error(info?.error);
        }
    };
};

export const useLoginGoogle = (setError: (value: string) => void, onDone: () => void) => {
    const onVerifyKey = useVerrifyAccount();
    const data = React.useContext(DataContext);
    return async (info: any) => {
        if (!info?.error) {
            fetch(`${process.env.REACT_APP_API}/api/v1/login/login_google`, {
                method: 'POST',
                body: JSON.stringify({
                    tokenId: info?.tokenId,
                }),
                headers: {
                    'Content-type': 'application/json',
                },
            })
                .then((res) => res.json())
                .then((result) => {
                    if (result.error_code === 0) {
                        localStorage.setItem('persist:account', JSON.stringify(result.data));
                        data?.setUser(result.data);
                        setError('');
                        toast.success('Login successful');
                        onDone();
                    } else {
                        for (let attr in ERROR_CODE) {
                            if (ERROR_CODE[attr] === parseInt(result.error_code) && parseInt(result.error_code) !== 1) {
                                setError(attr);
                                toast.error(attr);
                            }
                        }
                        onVerifyKey(result);
                    }
                });
        } else {
            toast.error(info?.error);
        }
    };
};

export const useLogout = () => {
    const data = React.useContext(DataContext);
    const onDisconnect = useDisconnectWallet();
    return () => {
        data?.setUser(null);
        onDisconnect();
        localStorage.removeItem('persist:account');
    };
};
export const useCheckRegistered = () => {
    const wallet = useWallet();
    return useQuery(
        ['useCheckRegistered.name'],
        () => {
            if (wallet.account) {
                return fetch(`${process.env.REACT_APP_API}/api/v1/user/check_register`, {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        address: wallet.account,
                    }),
                })
                    .then((result) => result.json())
                    .then((result) => result.data.registered);
            }
            return false;
        },
        {
            refetchInterval: 3000,
        },
    );
};
