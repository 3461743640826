import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, ModalProps, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';

interface IAppModalProps extends Omit<ModalProps, 'children'> {
    title: string;
    children:
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>[]
        | string;
    open: boolean;
    containerClassName?: string;
}

export function AppModal({ children, title, open, containerClassName, onClose, ...props }: IAppModalProps) {
    const theme = useTheme();

    useEffect(() => {
        document.body.style.position = 'fixed';

        return () => {
            document.body.style.position = 'unset';
        };
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            className="ss"
            sx={{
                height: '100vh',
            }}
            BackdropProps={{ sx: { height: '100vh' } }}
            {...props}
        >
            <Box
                sx={{
                    borderRadius: '10px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: { xs: '30px 16px', md: '30px 32px' },
                    maxHeight: '82%',
                    overflow: 'hidden',
                    overflowY: 'auto',
                    background: theme.color.bg,
                    '&:focus': {
                        outline: 'none !important',
                        outlineWidth: '0',
                    },

                    [theme.breakpoints.down('md')]: {
                        width: 343,
                    },
                }}
                className={containerClassName}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
                    <Typography variant="h5" component="h3" color={theme.color.textWhite}>
                        {title}
                    </Typography>
                    {onClose && <CloseIcon onClick={(e) => onClose(e, 'backdropClick')} sx={{ cursor: 'pointer' }} />}
                </Stack>
                {children}
            </Box>
        </Modal>
    );
}
