import React from 'react';
import './styles.scss';
import { Container } from 'src/components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useNavigate } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_API_SERVER_ENDPOINT;

type Props = {
    coverImage: string;
    isFavorite: boolean;
    handleFavorite: any;
};

function CreatorHeader({ coverImage, isFavorite, handleFavorite }: Props) {
    const navigate = useNavigate();

    const handleBtnBackClick = () => {
        navigate(-1);
    };

    return (
        <div
            className="creator-header"
            style={{
                backgroundImage:
                    coverImage && coverImage.indexOf('http') === 0
                        ? `url(${coverImage})`
                        : `url(${BASE_URL}/${coverImage})`,
            }}
        >
            <div className="creator-header__nav">
                <Container>
                    <>
                        <button type="button" className="btn btn-back" onClick={handleBtnBackClick}>
                            <ArrowBackIcon />
                        </button>

                        <button type="button" className="btn btn-heart" onClick={handleFavorite}>
                            <span>Favorite</span>
                            {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                        </button>
                    </>
                </Container>
            </div>
        </div>
    );
}

export default CreatorHeader;
