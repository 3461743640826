import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Box, FormHelperText, InputBase, InputBaseProps, InputLabel, SxProps, Theme, useTheme } from '@mui/material';
import React, { useState } from 'react';

interface IAppInputProps extends Omit<InputBaseProps, 'errors'> {
    variant?: 'validate' | 'normal';
    errors?: string | boolean;
    label?: string;
    name?: string;
    sxBox?: SxProps<Theme> | undefined;
}

export const AppInput = React.forwardRef(function AppInput(
    { sx, fullWidth, variant = 'normal', errors, onFocus, onBlur, label, name, sxBox, ...props }: IAppInputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
) {
    const theme = useTheme<Theme>();
    const isNormal = variant === 'normal';
    const [isFocus, setIsFocus] = useState<boolean>(false);

    return (
        <Box sx={{ ...sxBox }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                {label && <InputLabel sx={{ color: theme.color.textWhite, fontWeight: 700 }}>{label}</InputLabel>}
                {errors && (
                    <FormHelperText
                        sx={{
                            color: theme.color.error,
                            fontSize: 11,
                            mt: 0,
                        }}
                    >
                        {errors}
                    </FormHelperText>
                )}
            </Box>
            <InputBase
                ref={ref}
                fullWidth
                name={name}
                sx={{
                    border: `1px solid ${theme.color.border}`,
                    borderRadius: '8px',
                    fontWeight: 500,
                    borderColor: isFocus ? theme.color.border1 : '',
                    mt: '5px',

                    'label + &': {
                        // marginTop: theme.spacing(3),
                    },

                    '&:focus': {},

                    '& .MuiInputBase-input': {
                        position: 'relative',
                        padding: '10px 16px',
                        fontSize: '14px',
                        lineHeight: '18px',
                        color: theme.color.textWhite,

                        '&::placeholder': {
                            color: theme.color.textWhite,
                        },

                    },
                    '& .Mui-disabled': {
                        color: theme.color.textWhite,
                        WebkitTextFillColor: `${theme.color.textWhite} !important`,
                    },
                    ...sx,
                }}
                onFocus={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
                    setIsFocus(true);
                    if (!onFocus) return;
                    onFocus(e);
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
                    setIsFocus(false);
                    if (!onBlur) return;
                    onBlur(e);
                }}
                endAdornment={
                    isNormal
                        ? null
                        : errors && (
                              <Box
                                  sx={{
                                      position: 'relative',
                                      color: theme.color.error,
                                      display: 'flex',
                                      alignItems: 'center',
                                      padding: '0 16px',
                                  }}
                              >
                                  <HighlightOffIcon />
                              </Box>
                          )
                }
                {...props}
            />
        </Box>
    );
});
