import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Container } from 'src/components';
import { Creator, Social } from 'src/types/creators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faTwitter, faReddit } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import IconHotDrop from 'src/assets/home/hotdrop-icon.svg';
import defaultAvatarImg from 'src/assets/images/default-avatar.png';
import blueTickImg from 'src/assets/images/blue-tick.png';

const BASE_URL = process.env.REACT_APP_API_SERVER_ENDPOINT;

const SOCIAL = {
    TWITTER: 'twitter',
    INSTAGRAM: 'instagram',
    TIKTOK: 'tiktok',
    REDDIT: 'reddit',
};

type Props = {
    creator: Creator;
    isSubscribe: boolean;
    handleSubscribe: any;
};

export function CreatorInfos({ creator, isSubscribe, handleSubscribe }: Props) {
    const [socialMap, setSocialMap] = useState<Map<string, Social>>();

    useEffect(() => {
        if (creator?.socials) {
            setSocialMap(new Map(creator.socials.map((social) => [social.name, social])));
        }
    }, [creator]);

    return (
        <div className="creator-infos">
            <Container>
                <>
                    <div className="creator-infos__grid">
                        <div className="creator-infos__avatar-group">
                            <div
                                style={{
                                    backgroundImage: creator?.avatar
                                        ? creator?.avatar && creator?.avatar.indexOf('http') === 0
                                            ? `url(${creator?.avatar})`
                                            : `url(${BASE_URL}${creator.avatar})`
                                        : `url(${defaultAvatarImg})`,
                                }}
                                className="avatar-img"
                            />
                            <div className="creator-infos__display-name">
                                <span
                                    title={`${creator?.firstName} ${creator?.lastName}`}
                                >{`${creator?.firstName} ${creator?.lastName}`}</span>
                                <img src={blueTickImg} alt="blue-tick-img" />
                            </div>
                            <div className="creator-infos__username" title={creator?.username}>
                                {creator?.username}
                            </div>
                        </div>

                        <button type="button" className="creator-infos__subscribe" onClick={handleSubscribe}>
                            {isSubscribe ? 'Subscribed' : 'Subscribe'}
                        </button>

                        {/* <button type="button" className="creator-infos__btn-dirty-dozen">
                            DIRTY DOZEN
                        </button> */}

                        <div className="creator-infos__socials">
                            {socialMap?.has(SOCIAL.TWITTER) && (
                                <a href={socialMap.get(SOCIAL.TWITTER)?.link} target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faTwitter as IconProp} className="icon" />
                                </a>
                            )}
                            {socialMap?.has(SOCIAL.INSTAGRAM) && (
                                <a href={socialMap.get(SOCIAL.INSTAGRAM)?.link} target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faInstagram as IconProp} className="icon" />
                                </a>
                            )}
                            {socialMap?.has(SOCIAL.TIKTOK) && (
                                <a href={socialMap.get(SOCIAL.TIKTOK)?.link} target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faTiktok as IconProp} className="icon" />
                                </a>
                            )}
                            {socialMap?.has(SOCIAL.REDDIT) && (
                                <a href={socialMap.get(SOCIAL.REDDIT)?.link} target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faReddit as IconProp} className="icon" />
                                </a>
                            )}
                        </div>
                    </div>

                    <div className="creator-infos__other">
                        <div className="creator-infos__account-infos">
                            <div className="drops">
                                <p>-</p>
                                <p>Drops</p>
                            </div>
                            <div className="drops">
                                <p>-</p>
                                <p>Favorites</p>
                            </div>
                            <div className="drops">
                                <p>{creator?.followers || '-'}</p>
                                <p>Fans</p>
                            </div>
                        </div>

                        <button type="button" className="creator-infos__btn-dirty-dozen">
                            DIRTY DOZEN
                        </button>

                        {/* <div className="creator-infos__personal-infos">
                            <span>
                                <img src={birthdayIcon} alt="birthday-icon" />
                                <span>{creator?.birthday}</span>
                            </span>
                            <span>
                                <img src={eyesIcon} alt="eyes-icon" />
                                <span>{creator?.eyeColor}</span>
                            </span>
                            <span>
                                <img src={hairIcon} alt="hair-icon" />
                                <span>{creator?.hairColor}</span>
                            </span>
                            <span>
                                <img src={libraIcon} alt="libra-icon" />
                                <span>{creator?.ethnicity}</span>
                            </span>
                        </div> */}
                    </div>

                    <div className="creator-infos__trending-tags">
                        <h4>
                            <img src={IconHotDrop} alt="icon-hotdrop" />
                            <span>TRENDING IN</span>
                        </h4>

                        <div className="tags">
                            <span className="tag">#awardwinner</span>
                            <span className="tag">#centerfold</span>
                        </div>
                    </div>
                </>
            </Container>
        </div>
    );
}
