import { ChainId } from 'src/configs/Connect/Configs.chainId';

export const HOT_DROPS_CONTRACT = {
    [ChainId.MAINNET]: '',
    [ChainId.RINKEBY]: '0x34962b7b31eABc6DEF468F66743662070fAB4419',
};

export const BADGES_CONTRACT = {
    [ChainId.MAINNET]: '',
    [ChainId.RINKEBY]: '0xf806398D23FE760669b70803bEc35D8Ac34e3261',
};

export const EAP_TOKEN_ADDRESS = {
    [ChainId.MAINNET]: '',
    [ChainId.RINKEBY]: '0x982Df2dF95B0126722bF6F2f076e05F4f4f4f635',
};

export const HOT_DROPS_1155_CONTRACT = {
    [ChainId.MAINNET]: '',
    [ChainId.RINKEBY]: '0x27e41f9a4cC841A472674a5C121117F69713f568',
};
