import { createTheme } from '@mui/material/styles';
import { ThemeOptions as ThemeOptionsOld } from '@mui/material/styles/createTheme';

// LINE_HEIGHT
const LINE_HEIGHT = '24px';

// Colors
const themeColors = {
    size: {
        menu: '256px',
    },
    color: {
        main: `#FF8200`,
        // Brand
        secondary1: '#5C98FF',
        secondary4: '#C35CFF',
        // Product - State
        error: '#FF5555',
        success: '#58BD7D',
        warning: '#FFD166',

        red: '#FF3E3E',
        dark: '#121212',
        // Greys
        gray3: '#E7E9ED',
        gray2: '#F6F6F6',
        gray4: '#C4C8D2',
        gray6: '#55617E',
        gray5: '#99A0B2',
        gray7: '#242835',
        hover: '#DEE0E3',

        // ===================== Background =====================
        bg: '#212127',
        bg2: '#1B1B1D',
        bg3: '#EA4336',
        bg4: '#464646',
        
        // ===================== Background Button =====================
        bgBtn: 'linear-gradient(105deg, #FF3E3E 0%, #FF8200 100%)',
        bgBtn2: '#212127 0% 0% no-repeat padding-box',
        bgBtn3: 'rgba(255, 130, 0, 0.15)',

        bgBlock: '#fff',
        bgBlock2: '#F2F2F2',
        bgBlock3: '#EFF5FF',
        bgBlock4: '#f7a4494d',
        bgGradient: 'linear-gradient(99.51deg, #FF3E3E 8.87%, #FF8200 94.14%)',
        bgGradient2: 'linear-gradient(99.51deg, #FF3E3E33 8.87%, #FF820033 94.14%)',

        // ===================== Text =====================
        // Light
        text: '#414141',
        text2: '#5D5D5D',
        text3: '#55617E',
        text4: '#5C98FD',
        text5: '#404040',
        text6: '#ACACAC',
        textRed: '#FF6060',
        textWhite: '#FFF',
        textBlack: '#000',

        // ===================== Border =====================
        border: '#404040',
        border1: '#0082F3',
        border2: '#FF8200',
        border3: '#55617e4d',
        border4: '#A2A2BA',
        borderBtn: '#725CFF',
        borderBtnSecondary: 'rgba(85, 97, 126, 0.3)',

        // ===================== BoxShadow =====================
        boxShadow: '0 3px 11px rgb(0 0 0 / 4%)',
        boxShadow2: 'rgb(0 0 0 / 7%) 0px 7px 20px',
        boxShadow3: '0 0 0 0.2rem rgba(0,123,255,.5)',
        boxShadow4: '0px 16px 64px rgba(31, 47, 70, 0.15)',
    },
} as const;

// Override style Mui
const themeOptions: ThemeOptionsOld = {
    ...themeColors,
    // breakpoints
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900, // 900
            lg: 1300, // 1200
            xl: 1536,
        },
    },
    //
    palette: {
        primary: {
            main: themeColors.color.main,
        },
        secondary: {
            main: themeColors.color.main,
        },
        // error: {
        //   // main: red.A400,
        // },
    },
    //
    typography: {
        fontSize: 14,
        fontFamily: 'Montserrat',
    },
    //
    components: {
        MuiList: {
            styleOverrides: {
                root: {
                    background: 'white',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: '14px',
                    lineHeight: LINE_HEIGHT,
                    padding: 0,
                    margin: 0,

                    '& .MuiFormControlLabel-label.MuiTypography-root': {
                        fontSize: '14px',
                    },
                },
                a: {
                    color: 'inherit',
                    textDecoration: 'none',
                },
                '*': {
                    boxSizing: 'border-box',
                },
            },
        },
        MuiContainer: {
            defaultProps: {
                // fixed: false,
                disableGutters: true,
            },
            styleOverrides: {
                fixed: {
                    maxWidth: 'auto',
                },
                root: {
                    maxWidth: '1184px',
                    // maxWidth: '1176px!important',
                    // padding: '0 !important',
                    // '@media (min-width: 900px)': {
                    //   maxWidth: '600px',
                    // },
                    // '@media (min-width: 1200px)': {
                    //   maxWidth: '900px',
                    // },
                    // '@media (min-width: 1536px)': {
                    //   maxWidth: '1200px',
                    // },
                },
            },
        },

        MuiButtonBase: {
            styleOverrides: {
                root: {
                    // color: '#000!important',
                    '&&&:hover': {
                        // backgroundColor: 'transparent',
                    },

                    '&.Mui-disabled': {
                        color: '#B1B5C3',
                    },
                },
            },
        },

        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: themeColors.color.main,
                    '&&&:hover': {
                        background: 'none',
                    },
                    '&.Mui-checked': {
                        color: themeColors.color.main,
                    },
                },
            },
        },

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E6E8EC',
                    },
                    '&:hover': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#E6E8EC',
                        },
                    },

                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E6E8EC',
                        borderWidth: 1,
                    },
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                adornedStart: {
                    height: 40,
                    borderRadius: '40px !important',
                    fontSize: 12,
                },
                root: {
                    color: themeColors.color.gray6,
                    lineHeight: LINE_HEIGHT,
                    '&:before': {
                        border: 'none !important',
                    },
                    '&:after': {
                        border: 'none !important',
                    },
                },
            },
        },

        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: '#fff',
                    color: '#000',
                    boxShadow: 'none',
                    borderBottom: '1px solid',
                    borderColor: themeColors.color.border2,
                    marginTop: '0px',
                },
            },
        },

        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: '#1652f0',
                },
            },
        },

        MuiTab: {
            styleOverrides: {
                root: {
                    padding: 27,
                    textTransform: 'unset',
                    backgroundColor: '#fff',
                    '&.Mui-selected': {
                        color: '#1652f0',
                    },
                },
            },
        },

        MuiPaper: {
            styleOverrides: {
                root: {
                    '& .MuiMenuItem-root': {
                        color: themeColors.color.text,
                        padding: '11px 16px',
                        fontWeight: 500,
                        '&:hover': {
                            backgroundColor: `${themeColors.color.hover} !important`,
                        },
                        '&.Mui-selected': {
                            backgroundColor: themeColors.color.hover,
                        },
                    },
                },
                rounded: {
                    marginTop: '10px',
                    borderRadius: '12px',
                    boxShadow: '0px 16px 64px -11px rgba(31, 47, 70, 0.15)',
                },
            },
        },

        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                },
            },
        },

        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: '72px !important',

                    '@media (min-width: 900px)': {
                        minHeight: '76px !important',
                    },
                },
            },
        },

        MuiRadio: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {
                    color: themeColors.color.main,

                    '&+.MuiFormControlLabel-label': {
                        fontWeight: 500,
                        color: themeColors.color.gray6,
                    },
                    '&.Mui-checked+.MuiFormControlLabel-label': {
                        color: themeColors.color.main,
                    },
                },
            },
        },

        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontWeight: 'bold',
                    fontSize: '48px',
                    lineHeight: '62.4px'
                },
                h2: {
                    fontWeight: 'bold',
                    fontSize: '36px',
                    lineHeight: '46.8px',
                },
                h3: {
                    fontWeight: 'bold',
                    fontSize: '30px',
                    lineHeight: '39px',
                },
                h4: {
                    fontWeight: 'bold',
                    fontSize: '24px',
                    lineHeight: '36px',
                },
                h5: {
                    fontWeight: 'bold',
                    fontSize: '18px',
                    lineHeight: '27px',
                },
                body1: {
                    fontSize: '16px',
                },
                body2: {
                    fontSize: '14px',
                },
            },
        },
    },
};

// Update for Typescript
type CustomTheme = {
    [Key in keyof typeof themeColors]: typeof themeColors[Key];
};
declare module '@mui/material/styles/createTheme' {
    interface Theme extends CustomTheme {}
    interface ThemeOptions extends CustomTheme {}
}

// Create theme
export const theme = createTheme({ ...themeColors, ...themeOptions });
