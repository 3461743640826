import React from 'react';
import './styles.scss';

type CollectHottestDrop = {
    name: string;
    image: string;
    price: string;
    icon: string;
};
export function HottestCard({ name, price, image, icon }: CollectHottestDrop) {
    return (
        <div className="card-container">
            <div className="image-tiny">
                <img
                    src={require(`../../../../../assets/images/home/${image}.png`).default}
                    alt="avatar"
                    className="image-content"
                />
            </div>
            <div className="icon">
                <img
                    src={require(`../../../../../assets/images/home/${icon}.png`).default}
                    alt="icon"
                    className="icon__content"
                />
            </div>
            <div className="name"> {name} </div>
            <div className="price"> {price}</div>
        </div>
    );
}
