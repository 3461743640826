/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerSchema } from '../validate-schema';
import authApi from '../../../api/auth';
import Countdown from './countdown';

export default function RegisterForm(props: any) {
    const { onClose, toggleLogin } = props;
    const [defaultValues] = useState({
        email: '',
        password: '',
        username: '',
        isAccept: false,
        isOverAge: false,
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues,
        resolver: yupResolver(registerSchema),
    });

    const [verifyModal, setVerifyModal] = useState(false);
    const [wanringModal, setWaringModal] = useState(false);
    const [registerInfo, setRegisterInfo] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [codeOtp, setCodeOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [emailError, setEmailError] = useState<any>();
    const [userNameError, setUserNameError] = useState<any>();
    const [codeExpireTime, setCodeExpireTime] = useState<any>();

    const [valueEmail, setValueEmail] = useState('');
    const [valueUserName, setValueUserName] = useState('');

    const handleWarningModalOpen = () => {
        setWaringModal(true);
    }

    const handleWarningModalClose = () => {
        setWaringModal(false);
    }

    const handleVerifyModalOpen = () => {
        setVerifyModal(true);
    };

    const handleVerifyModalClose = () => {
        setVerifyModal(false);
    };

    const onChangeOtp = (e: any) => {
        setCodeOtp(e.target.value);
        setOtpError('');
    };

    const onClosePopup = () => {
        onClose();
        reset(defaultValues);
        setEmailError('');
        setUserNameError('');
        setOtpError('');
    };

    const onRegisterSubmit = async (data: any) => {
        try {
            setIsLoading(true);
            let otpInput = {
                username: data?.username,
                email: data?.email,
            };
            setRegisterInfo(data);
            const res = await authApi.sendOtp(otpInput);
            if (res?.status === 201) {
                setIsLoading(false);
                handleVerifyModalOpen();
                setCodeExpireTime(moment().add(1, 'm'));
            } else if (res?.status === 400) {
                setIsLoading(false);
                setEmailError(res?.data?.message?.find((el: any) => Object.keys(el).includes('email')));
                setUserNameError(res?.data?.message?.find((el: any) => Object.keys(el).includes('username')));
            } else if (res?.status === 403) {
                setIsLoading(false);
                toast.error(res?.data?.message, {
                    position: 'top-center',
                });
            }
        } catch (err: any) {
            setIsLoading(false);
            toast.error(err?.message, {
                position: 'top-center',
            });
        }
    };

    const resendOtp = async () => {
        try {
            let otpInput = {
                username: registerInfo?.username,
                email: registerInfo?.email,
            };
            const res = await authApi.sendOtp(otpInput);
            if (res?.status === 201) {
                toast.success('New code has been sent to your email', {
                    position: 'top-center',
                });
                setCodeExpireTime(moment().add(1, 'm'));
            } else if (res?.status === 403) {
                toast.error(res?.data?.message, {
                    position: 'top-center',
                });
            }
        } catch (err: any) {
            toast.error(err?.message, {
                position: 'top-center',
            });
        }
    };

    const registerUser = async () => {
        try {
            if (!codeOtp) {
                setOtpError('The code field is required');
            } else {
                setIsLoading(true);
                let info = { ...registerInfo, otp: codeOtp };
                const res = await authApi.register(info);
                if (res?.status === 201) {
                    setIsLoading(false);
                    toast.success('You have successfully signed up!', {
                        position: 'top-center',
                        toastId: "registerId"
                    });
                    handleVerifyModalClose();
                    toggleLogin();
                } else if (res?.status === 400) {
                    setIsLoading(false);
                    toast.error('Incorrect code. Please try again', {
                        position: 'top-center',
                    });
                }
            }
        } catch (err: any) {
            setIsLoading(false);
            toast.error(err?.message, {
                position: 'top-center',
            });
        }
    };

    return (
        <>
            <div onClick={onClosePopup} className="overlay" />
            <div className="authenticate__form">
                <CloseIcon onClick={onClosePopup} />
                <span className="title">Sign up for Hot Drops</span>
                {/* <div className="authenticate__form--thirdparty">
                    <FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_ID || ''}
                        fields="name,email,picture"
                        onClick={() => console.log('clicked')}
                        callback={(response: any) => onLoginFacebook(response)}
                        textButton="Continue with Facebook"
                        icon="fa-facebook"
                    />
                    <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_ID || ''}
                        buttonText="Continue with Google"
                        onSuccess={(response) => onLoginGoogle(response)}
                        onFailure={(response) => toast.error(response?.error)}
                        cookiePolicy="single_host_origin"
                    />
                </div> */}
                <form onSubmit={handleSubmit(onRegisterSubmit)} className="form-container">
                    {/* <div className="border" />
                    <div className="border-text">or</div> */}
                    <div className="form-item">
                        <div className="label">
                            <div className="label-name">Email</div>
                            <div className="label-error">
                                {errors?.email?.message || emailError?.email?.constraints[0]}
                            </div>
                        </div>
                        <div className="input-field">
                            <input
                                {...register('email', {
                                    onChange: (e) => {
                                        setEmailError('');
                                        setValueEmail(e.target.value.replace(/\s/g, ''));
                                    },
                                })}
                                type="text"
                                value={valueEmail}
                                maxLength={100}
                            />
                            {(errors?.email || emailError?.email) && (
                                <HighlightOffIcon className="input-error-suffix" />
                            )}
                        </div>
                    </div>
                    <div className="form-item">
                        <div className="label">
                            <div className="label-name">Create Password</div>
                            <div className="label-error">{errors?.password?.message}</div>
                        </div>
                        <div className="input-field">
                            <input {...register('password')} type="password" maxLength={16} />
                            {errors?.password && <HighlightOffIcon className="input-error-suffix" />}
                        </div>
                    </div>
                    <div className="form-item">
                        <div className="label">
                            <div className="label-name">Username</div>
                            <div className="label-error">
                                {errors?.username?.message || userNameError?.username?.constraints[0]}
                            </div>
                        </div>
                        <div className="input-field">
                            <input
                                {...register('username', {
                                    onChange: (e) => {
                                        setUserNameError('');
                                        setValueUserName(e.target.value.replace(/\s/g, ''));
                                    },
                                })}
                                value={valueUserName}
                                type="text"
                                maxLength={30}
                            />
                            {(errors?.username || userNameError?.username) && (
                                <HighlightOffIcon className="input-error-suffix" />
                            )}
                        </div>
                    </div>
                    <div className="form-item">
                        <div className="checkbox-field">
                            <input {...register('isAccept')} type="checkbox" id="term-checkbox" />
                            <span className="checkmark" />
                            <label htmlFor="term-checkbox">
                                I agree to the{' '}
                                <a
                                    target="_blank"
                                    href="https://beta.myhotdrops.com/terms-of-service-and-use/"
                                    rel="noreferrer"
                                >
                                    Terms of Service
                                </a>
                                .{' '}
                                <a
                                    target="_blank"
                                    href="https://www.iubenda.com/privacy-policy/15957209"
                                    rel="noreferrer"
                                >
                                    Privacy Policy
                                </a>
                                ,{' '}
                                <a
                                    href="https://beta.myhotdrops.com/content-removal-request/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    GDPR
                                </a>{' '}
                                and{' '}
                                <a
                                    href="https://beta.myhotdrops.com/content-removal-request/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    CCPA
                                </a>
                                .
                            </label>
                        </div>
                        <div className="checkbox-field-error">{errors?.isAccept?.message}</div>
                    </div>
                    <div className="form-item">
                        <div className="checkbox-field">
                            <input {...register('isOverAge')} type="checkbox" id="age-checkbox" />
                            <span className="checkmark" />
                            <label htmlFor="age-checkbox">
                                I am over <span className="age-warning" onClick={(e) => {
                                    e.preventDefault();
                                    handleWarningModalOpen();
                                }}>18 years old</span>.
                            </label>
                        </div>
                        <div className="checkbox-field-error">{errors?.isOverAge?.message}</div>
                    </div>
                    <button className="btn-submit" type="submit" disabled={isLoading}>
                        {isLoading ? <CircularProgress /> : 'Create Account'}
                    </button>
                </form>
                <span className="switch-tab">
                    Already have an account?{' '}
                    <span
                        onClick={() => {
                            toggleLogin();
                        }}
                    >
                        Sign In
                    </span>
                </span>
            </div>
            <Modal
                open={verifyModal}
                onClose={handleVerifyModalClose}
                BackdropProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 1)',
                    },
                }}
            >
                <div className="verify-modal">
                    <KeyboardArrowLeftIcon className="back-icon" onClick={handleVerifyModalClose} />
                    <div className="verify-modal-top">
                        <div className="verify-modal-top__title">Verify Email</div>
                        <div className="verify-modal-top__subtitle">Enter the code we’ve sent to your email:</div>
                        <div className="verify-modal-top__subtitle">{registerInfo?.email}</div>
                        {/* <div className="text-action" onClick={handleVerifyModalClose}>Change</div> */}
                    </div>
                    <div className="verify-modal-body">
                        <div className="verify-modal-body__label">
                            <div className="label-name">Code</div>
                            <div className="label-error">{otpError}</div>
                        </div>
                        <div className="verify-modal-body__input">
                            <input type="text" onChange={onChangeOtp} />
                            {otpError && <HighlightOffIcon className="input-error-suffix" />}
                        </div>
                        <Countdown eventTime={codeExpireTime} resendOtp={resendOtp} />
                    </div>
                    <div className="verify-modal-bottom">
                        <button className="btn-submit" type="button" onClick={registerUser} disabled={isLoading}>
                            {isLoading ? <CircularProgress /> : 'Next'}
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                open={wanringModal}
                onClose={handleWarningModalClose}
                BackdropProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 1)',
                    },
                }}
            >
                <div className="verify-modal">
                    <CloseIcon onClick={handleWarningModalClose} className="close-icon" />
                    <div className="verify-modal-top">
                        <div className="verify-modal-top__title warning-title">Warning Sexually Explicit Adult Content</div>
                        <div className="verify-modal-top__subtitle large-content">
                            I am at least 18 years of age and have the legal right to access and possess adult material in the community from which I am accessing these materials and in which I reside. I will not permit any person(s) under the age of 18 (or who are otherwise not legally permitted) to have access to any of the materials contained on this website. I acknowledge that I am voluntarily requesting and choosing to receive the materials described above for my own personal use, information and/or education and that in no way am I being sent any information without my permission. I am not offended by materials of an adult nature, nor do I find such materials objectionable. I will exit from the website immediately in the event that I am in any way offended by any material found on the website. I am familiar with, understand and agree to comply with the standards and laws of the community in which I live and from which I am gaining access. I agree that I will not hold the creators, owners or operators of this website, or their employees, responsible for any materials or links contained on these pages. I understand that if I violate these terms or any provision of the Terms of Use, I may be in violation of federal, state, and/or local laws or regulations, and that I am solely responsible for my actions. I agree that the Electronic Signatures in Global and National Commerce Act (commonly known as the "E-Sign Act"), 15 U.S.C. 7000, et seq, governs this agreement and that by clicking on "Enter" I am confirming my agreement to be bound by the terms of this agreement and affirmatively adopt the signature line below as my signature as the manifestation of my consent to be bound by the terms of this agreement.
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
