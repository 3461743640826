import { SxProps } from '@mui/material';
import React from 'react';
import NumberFormat from 'react-number-format';
import { AppInput } from "../AppInput";
import { NumberFormatProps } from './AppInputAmount';

// interface AppInputAmount extends Omit<NumberFormatProps, 'onChange'> {
interface AppInputAmountProps extends NumberFormatProps {
    sx?: SxProps;
}

export function AppInputAmount({
    decimalScale = 6, // Default USDT
    ...props
}: AppInputAmountProps) {
    return (
        <NumberFormat
            customInput={AppInput}
            thousandsGroupStyle="thousand"
            prefix=""
            decimalSeparator="."
            displayType="input"
            type="text"
            thousandSeparator
            allowNegative={false}
            decimalScale={decimalScale}
            {...props}
        />
    );
}
