import './styles.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import VipImage from 'src/assets/images/vip.png';
import { Link } from 'react-router-dom';
import { AppButton } from 'src/components';
import { Box, useTheme } from '@mui/material';
import { onMoveAnimation } from '../../../../services/hooks/useDevelopUI';
import { RootState } from '../../../../app/store';

export function Vip() {
    const theme = useTheme();
    const authSelector = useSelector((state: RootState) => state.auth);

    return (
        <div className="vip-container">
            <div className="vip-left">
                <h1 className="title-mobile-screen">GET THE COLLECTOR ACCESS PASS</h1>
                <img src={VipImage} alt="vipimage" />
            </div>
            <div className="vip-right">
                <h1 className="title-desktop-screen">GET THE COLLECTOR ACCESS PASS</h1>
                <Box
                    sx={{
                        position: 'relative',
                        color: theme.color.textWhite,
                        textAlign: 'left',
                        letterSpacing: '0px',
                        opacity: 1,
                        marginTop: '7px',
                        marginBottom: '38px',
                        fontSize: '15px',

                        [theme.breakpoints.down(991)]: {
                            fontSize: '14px',
                            textAlign: 'center',
                        },

                        [theme.breakpoints.down(375)]: {
                            width: '80%',
                        },
                    }}
                >
                    To receive Early access to drops from the hottest creators and a discount on Hot Drops NFT purchases
                    for 1 year!
                </Box>

                {!authSelector?.currentUser?.id ? (
                    <div className="row-buttons">
                        <AppButton
                            className="get-access-button"
                            onClick={() => onMoveAnimation('authenticate-form', 'moveInOpacity')}
                        >
                            GET ACCESS PASS
                        </AppButton>
                        <AppButton
                            className="learnmore-button"
                            onClick={() => onMoveAnimation('authenticate-form', 'moveInOpacity')}
                            color="black"
                        >
                            LEARN MORE
                        </AppButton>
                    </div>
                ) : (
                    <div className="row-buttons">
                        <Link to="/eap">
                            <AppButton className="get-access-button">GET ACCESS PASS</AppButton>
                        </Link>
                        <Link to="/eap" state="view-benefits">
                            <AppButton className="learnmore-button" color="black">LEARN MORE</AppButton>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
}
