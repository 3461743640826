import React from 'react';
import { Link } from 'react-router-dom';
import { Close } from '@material-ui/icons';
import './styles.scss';
import { menuItemDesktop } from '../Header/hard';

export function NavigateMobile() {
    return (
        <div className="navigate-mobile-container">
            <label htmlFor="close-icon" className="close-icon-label">
                <input type="checkbox" id="close-icon" />
                <Close />
            </label>

            {menuItemDesktop.map((item, key) => (
                <Link className="item" to={item.path} key={key.toString() + item}>
                    {item.name}
                </Link>
            ))}
        </div>
    );
}
