import axios from 'axios';

const endpoint = process.env.REACT_APP_API_SERVER_ENDPOINT;

export const baseRequest = async (method: any, url: any, data?: any) => {

    // if(method.toLowerCase() === 'get') {
    //     let newUrl: any = new URL(endpoint + url);
    //     newUrl.search = new URLSearchParams(data);
    //     url = newUrl;
    // }

    try {
        const api = await axios.request({
            baseURL: endpoint,
            method,
            url,
            data,
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem('access_token')}`
            }
        });
        return api;
    } catch(err: any) {
        if(err.response) {
            return err.response;
        }
    }
};
