import React, { useState } from 'react';
import { onMoveAnimation } from 'src/services/hooks/useDevelopUI';
import './styles.scss';
import LoginForm from './login-form';
import RegisterForm from './register-form';

export function AuthenticateForm() {
    const [typeForm, setTypeForm] = useState(1);

    const onClose = () => {
        setTypeForm(1);
        onMoveAnimation('authenticate-form', 'moveOutOpacity');
    };
    const toggleLogin = () => {
        setTypeForm(1);
    };

    const toggleRegister = () => {
        setTypeForm(2);
    }

    const renderAuthenticateForm = () => {
        if (typeForm === 1) {
            return <LoginForm onClose={onClose} toggleRegister={toggleRegister} />
        }
        return <RegisterForm onClose={onClose} toggleLogin={toggleLogin} />
    }

    return (
        <div id="authenticate-form" className="authenticate">
            {renderAuthenticateForm()}
        </div>
    );
}
