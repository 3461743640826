/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import authApi from '../../api/auth';
import userApi from '../../api/user';

const initialState: any = {
    currentUser: {},
    isLoading: false,
    errorMessage: '',
};

export const login = createAsyncThunk('auth/login', async (payload: { data: any; onClose: any, onSuccess: any }) => {
    try {
        const res = await authApi.login(payload.data);
        if (res?.status === 201) {
            localStorage.setItem('access_token', res?.data?.data?.accessToken);
            const resUser = await userApi.getUserProfile();
            toast.success('Login Successful!', {
                position: 'top-center',
            });
            payload.onClose();
            payload.onSuccess();
            return resUser;
        }
        return res;
    } catch (err) {
        console.log('login error', err);
    }
});

export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logOut: (state) => {
            state.currentUser = {};
            localStorage.removeItem('access_token');
        },
        toVIP: (state) => {
            return { state, currentUser: { ...state.currentUser, type: 1 } };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(login.fulfilled, (state, action) => {
            state.isLoading = false;
            if (action.payload?.status === 401) {
                state.currentUser = {};
                localStorage.removeItem('access_token');
                toast.error(action.payload?.data?.message, {
                    position: 'top-center',
                });
            }
            state.currentUser = action.payload?.data?.data;
        });

        builder.addCase(login.rejected, (state, action) => {
            state.isLoading = false;
            console.log('login rejected', action);
        });
    },
});

export const { logOut, toVIP } = AuthSlice.actions;

export default AuthSlice.reducer;
