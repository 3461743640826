export const HottestList = [
    {
        name: 'ywrr',
        price: '$140',
        icon: '46_profile_photo',
        image: 'drops_1',
    },
    {
        name: 'tinapay',
        price: '$220',
        icon: '46_profile_photo',
        image: 'drops_2',
    },
    {
        name: 'noxide',
        price: '$130',
        icon: '46_profile_photo',
        image: 'drops_3',
    },
    {
        name: 'benbenben',
        price: '$1290',
        icon: '46_profile_photo',
        image: 'drops_4',
    },
    {
        name: 'cantaloupe',
        price: '$540',
        icon: '46_profile_photo',
        image: 'drops_4',
    },
];
