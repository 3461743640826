import React from 'react';
import './styles.scss';
import { Container } from 'src/components';
import comingoonDropImg from 'src/assets/images/comingsoon-drops.png';

type Props = {
    name: string;
};

function CreatorDrops({ name }: Props) {
    return (
        <div className="creator-drops">
            <Container>
                <>
                    <div className="creator-drops__heading">
                        <h1>{name}&apos;s</h1>
                        <p>DROPS COMING SOON</p>
                    </div>

                    <div className="creator-drops__drops">
                        <img src={comingoonDropImg} alt="comingsoon-drops" />
                        <img src={comingoonDropImg} alt="comingsoon-drops" />
                        <img src={comingoonDropImg} alt="comingsoon-drops" />
                        <img src={comingoonDropImg} alt="comingsoon-drops" />
                        <img src={comingoonDropImg} alt="comingsoon-drops" />
                    </div>
                </>
            </Container>
        </div>
    );
}

export default CreatorDrops;
