import React from 'react';
import './styles.scss';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CreateAccount } from 'src/pages/Home/components';
import { faInstagram, faTiktok, faTwitch, faDiscord, faReddit, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { NavigateItem } from './navigate-item';
import { NavigateList } from './hard';
import { RootState } from '../../app/store';

export function Footer() {
    const authSelector = useSelector((state: RootState) => state.auth);

    return (
        <>
            {!authSelector?.currentUser?.id && <CreateAccount />}
            <div className="footer-container">
                <div className="footer-wrapper">
                    <div className="footer-wrapper__list-navigate">
                        {NavigateList.map((item, key) => (
                            <div className="item-navigate-container" key={key.toString() + item}>
                                <NavigateItem title={item.title} subtitle={item.subtitle} key={key.toString() + item} />
                            </div>
                        ))}
                    </div>
                    <div className="footer-wrapper__list-icon-media">
                        <a href="https://twitter.com/myhotdrops">
                            <FontAwesomeIcon icon={faTwitter as IconProp} className="icon" />
                        </a>
                        <a href="https://www.instagram.com/myhotdropsnft/">
                            <FontAwesomeIcon icon={faInstagram as IconProp} className="icon" />
                        </a>

                        <FontAwesomeIcon icon={faTiktok as IconProp} className="icon" />
                        <FontAwesomeIcon icon={faDiscord as IconProp} className="icon" />
                        <FontAwesomeIcon icon={faTwitch as IconProp} className="icon" />
                        <FontAwesomeIcon icon={faReddit as IconProp} className="icon" />
                    </div>
                    <div className="footer-wrapper__copyright">© 2022 Hot Drops, Inc.</div>
                </div>
            </div>
        </>
    );
}
