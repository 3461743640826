/**
 *
 * Asynchronously loads the component
 *
 */

 import { lazyLoad } from '../../services/utils/loadable';

 export const CreatorsPage = lazyLoad(
     () => import('./index'),
     (module) => module.CreatorsPage,
 );
 