import React, { useState, useEffect } from 'react';
import moment from 'moment';

export default function Countdown(props: any) {
    const { eventTime, resendOtp } = props;
    const [minutes, setMinutes] = useState<any>();
    const [seconds, setSeconds] = useState<any>();
    const [showResend, setShowResend] = useState(true);

    const caculateTimeLeft = () => {
        const now = moment().valueOf();
        const then = moment(eventTime).valueOf();
        const countdown = then - now;

        const second = 1000;
        const minute = second * 60;
        const hour = minute * 60;

        const minutesLeft = Math.trunc((countdown % hour) / minute);
        const secondsLeft = Math.trunc((countdown % minute) / second);

        return {
            countdown,
            minutesLeft,
            secondsLeft
        }
    };

    useEffect(() => {
        let timer = setInterval(() => {
            const duration = caculateTimeLeft();

            if (duration.countdown <= 0) {
                clearInterval(timer);
                setShowResend(true);
            }
            else {
                setShowResend(false);
                setMinutes((`0${duration.minutesLeft}`).slice(-2));
                setSeconds((`0${duration.secondsLeft}`).slice(-2));
            }
        }, 1000)

        return () => {
            clearInterval(timer);
        }
    }, [eventTime])

    return (
        <div className="text-action">
            {!showResend ? (
                <div>{minutes || '00'} : {seconds || '00'}</div>
            ) : (
                <div onClick={resendOtp}>Resend</div>
            )}
        </div>
    )
}
