import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthenticateForm, ConnectModal } from 'src/components';
import { useAuthenticate } from 'src/services/hooks/useAuthenticate';
import { useListenWallet } from 'src/services/hooks/useWalletProviders';
import { useWallet } from 'use-wallet';
import { RootState } from './app/store';
import { masterDataWallet, transactionActions } from './app/transaction/reducer';
import { getUser, saveWallet } from './pages/Profile/slice';
import { logOut } from './components/AuthenticateModal/slice';

export function ConsumeComponent() {
    useListenWallet();
    useAuthenticate();
    const dispatch = useDispatch();
    const { account, chainId, connector, ethereum } = useWallet();
    const location = useLocation();
    const navigate = useNavigate();

    const profileState = useSelector((state: RootState) => state.profile);

    useEffect(() => {
        dispatch(getUser());
    }, []);

    useEffect(() => {
        (async () => {
            if (chainId) {
                if (!Object.entries(profileState?.user || {}).length) return;
                if (!chainId || (connector !== 'walletconnect' && !window.ethereum)) {
                    return;
                }

                const provider = connector === 'walletconnect' ? ethereum : window.ethereum;
                dispatch(masterDataWallet({ chainId, provider, account }));
            }
        })();
    }, [chainId, account, ethereum, profileState?.user]);

    useEffect(() => {
        if (!Object.entries(profileState?.user || {}).length && location.pathname === '/profile') {
            navigate('/');
        }
        if (!localStorage.getItem('access_token')) {
            dispatch(logOut());
        }
    }, [profileState?.user, dispatch]);

    useEffect(() => {
        const isOwnWallet = profileState?.user?.wallets?.find(
            (el: any) => el?.address?.toLowerCase() === account?.toLowerCase(),
        );
        if (account && !isOwnWallet) {
            dispatch(saveWallet({ address: account }));
            dispatch(getUser());
        }
    }, [account]);

    useEffect(() => {
        dispatch(transactionActions.cancelCallback());
        dispatch(transactionActions.isPendingTransaction({ isPending: false }));
    }, [dispatch]);

    return (
        <>
            <ConnectModal />
            <AuthenticateForm />
        </>
    );
}
