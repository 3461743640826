/* eslint-disable no-useless-escape */
import * as yup from 'yup';

const REGEX_PASSWORD = /^(?=.*[0-9])(?=.*[a-zA-Z])([><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w])*$/;

export const loginSchema = yup.object().shape({
    email: yup.string().required('The email field is required.').email('Please enter a valid email address.'),
    password: yup
        .string()
        .required('The password field is required.')
});

export const registerSchema = yup.object().shape({
    email: yup.string().required('The email field is required.').email('Please enter a valid email address.'),
    password: yup
        .string()
        .required('The password field is required.')
        .min(8, 'Must contain 8-16 characters')
        .max(16, 'Must contain 8-16 characters')
        .matches(REGEX_PASSWORD, 'Must contain at least 1 number and letter'),
    username: yup.string().required('The username field is required.'),
    isAccept: yup.bool().oneOf([true], 'You must accept terms and conditions.'),
    isOverAge: yup.bool().oneOf([true], 'You must be 18 years old or older.'),
});

export const forgotPasswordSchema = yup.object().shape({
    email: yup.string().required('The email field is required.').email('Please enter a valid email address.'),
});

export const otpSchema = yup.object().shape({
    code: yup.string().required('The code field is required.'),
});

export const resetPasswordSchema = yup.object().shape({
    newPassword: yup
        .string()
        .required('The new password field is required.')
        .min(8, 'Must contain 8-16 characters')
        .max(16, 'Must contain 8-16 characters')
        .matches(REGEX_PASSWORD, 'Must contain at least 1 number and letter'),
    confirmPassword: yup
        .string()
        .required('The confirm new password field is required.')
        .oneOf([yup.ref('newPassword')], 'Passwords do not match'),
})
