import './style.scss';
import React from 'react';
import AdopterPass from '../../../../assets/images/adopter-pass.png';
import { onMoveAnimation } from '../../../../services/hooks/useDevelopUI';

export function SpecialPass() {
    return (
        <div className="special-pass-container">
            <div className="special-pass-container__left">
                <h1 className="title-desktop-screen">DON’T MISS OUT!</h1>
                <span>Sign-up for a free member account within the first 30 days of launch to receive a complimentary Early Adopter Badge!</span>
                <div className="row-buttons">
                    <div className="get-access-button" onClick={() => onMoveAnimation('authenticate-form', 'moveInOpacity')}>
                        GET EARLY ADOPTER BADGE
                    </div>
                    <div className="learnmore-button" onClick={() => onMoveAnimation('authenticate-form', 'moveInOpacity')}>
                        LEARN MORE
                    </div>
                </div>
            </div>
            <div className="special-pass-container__right">
                <h1 className="title-mobile-screen">DON’T MISS OUT!</h1>
                <img src={AdopterPass} alt="vipimage" />
            </div>
        </div>
    )
}
