import React, { useEffect, useState } from 'react';
import { AppButton, Container } from 'src/components';
import './styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { CollectHottest, Vip } from 'src/pages/Home/components';
import creatorApi from 'src/api/creator';
import { Creator } from 'src/types/creators';
import { Bio } from '../Bio';
import { Friends } from '../Friends';
import CreatorHeader from '../CreatorHeader';
import { CreatorInfos } from '../CreatorInfos';
import CreatorDrops from '../CreatorDrops';
import userApi from '../../../../api/user';
import { RootState } from '../../../../app/store';
import { onMoveAnimation } from '../../../../services/hooks/useDevelopUI';
import CreatorNftsList from '../CreatorNFTsList';

export function CreatorDetails() {
    const navigate = useNavigate();
    const authSelector = useSelector((state: RootState) => state.auth);
    const [creator, setCreator] = useState<Creator>({} as Creator);
    const [twitterProfile, setTwitterProfile] = useState('');
    const { username } = useParams();
    console.log(useParams())
    const fetchCreatorByUserName = async () => {
        if (!username) {
            return;
        }

        const creatorResponse = await creatorApi.fetchCreatorByUserName(username);
        if (!creatorResponse) return navigate("/creatorNotFound");
        setCreator(creatorResponse);
    };
    const [isFavorite, setIsFavorite] = useState(false);
    const [isSubscribe, setIsSubscribe] = useState(false);

    const openPopupSignIn = () => {
        onMoveAnimation('authenticate-form', 'moveInOpacity');
    };

    const handleFavoriteCreator = async () => {
        setIsFavorite(!isFavorite);
        let data = { creator_id: creator.id.toString() };
        if (!isFavorite) {
            await creatorApi.favoriteCreator(data);
        } else await creatorApi.unfavoriteCreator(data);
    };

    const handleSubscribeCreator = async () => {
        setIsSubscribe(!isSubscribe);
        let data = { creator_id: creator.id.toString() };
        if (!isSubscribe) {
            await creatorApi.subscribeCreator(data);
        } else await creatorApi.unsubscribeCreator(data);
    };

    const getUserFavorite = async () => {
        try {
            const res = await userApi.getUserFavorite();
            let listUserFavorite = res?.data?.data;
            let favoriteCreator = listUserFavorite?.find((item: any) => item?.id === creator.id);
            setIsFavorite(favoriteCreator || false);
        } catch (err) {
            console.log(err);
        }
    };

    const getUserSubscribe = async () => {
        try {
            const res = await userApi.getUserSubscribe();
            let listUserSubscribe = res?.data?.data;
            let subscribeCreator = listUserSubscribe?.find((item: any) => item?.id === creator.id);
            setIsSubscribe(subscribeCreator || false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if(!authSelector?.currentUser) return;
        if (!Object.entries(authSelector?.currentUser).length) return;
        getUserFavorite();
        getUserSubscribe();
    }, [authSelector?.currentUser, creator]);

    useEffect(() => {
        if (username) {
            fetchCreatorByUserName();
            window.scrollTo(0, 0);
        }
    }, [username]);

    useEffect(() => {
        if (creator && creator.socials) {
            const twitter = (creator as Creator).socials.find((social) => social.name === 'twitter');
            if (twitter && twitter.link) {
                setTwitterProfile(twitter.link.split('/').pop() || '');
            } else {
                setTwitterProfile('');
            }
        }
    }, [creator]);

    return (
        <div className="creator-details">
            <CreatorHeader
                coverImage={creator?.image}
                isFavorite={isFavorite}
                handleFavorite={authSelector?.currentUser?.id ? handleFavoriteCreator : openPopupSignIn}
            />

            <CreatorInfos
                creator={creator}
                isSubscribe={isSubscribe}
                handleSubscribe={authSelector?.currentUser?.id ? handleSubscribeCreator : openPopupSignIn}
            />

            <CreatorDrops name={creator.firstName} />
            <CreatorNftsList
                name={{
                    firstName: creator.firstName,
                    lastName: creator.lastName,
                    username: creator.username,
                }}
                id={creator.id}
            />

            <div className="creator-details__row-buttons">
                <div className="creator-details__get-access-pass">
                    <AppButton
                        className="get-access-button"
                        onClick={authSelector?.currentUser?.id ? () => navigate('/eap') : openPopupSignIn}
                    >
                        GET ACCESS PASS
                    </AppButton>
                </div>
            </div>

            {twitterProfile && (
                <div className="creator-details__twitter">
                    <Container>
                        <div className="content">
                            <h2>SOCIAL</h2>

                            <div className="tweet">
                                <TwitterTimelineEmbed
                                    sourceType="profile"
                                    screenName={twitterProfile}
                                    options={{ height: 600 }}
                                    theme="dark"
                                    transparent
                                    noScrollbar
                                    noBorders
                                    key={twitterProfile}
                                />
                            </div>
                        </div>
                    </Container>
                </div>
            )}

            <CollectHottest />

            <Bio creator={creator} />

            <Friends creatorId={creator?.id} name={creator.firstName} />

            <Container>
                <Vip />
            </Container>
        </div>
    );
}
