import { Box, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import creatorApi from 'src/api/creator';
import comingoonDropImg from 'src/assets/images/comingsoon-drops.png';
import { Container } from 'src/components';
import { INft } from 'src/types/nfts';
import musicIcon from 'src/assets/icons/music-icon.svg';
import videoIcon from 'src/assets/icons/video-icon.svg';
import './styles.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { onMoveAnimation } from 'src/services/hooks/useDevelopUI';
import ModalBuyNft from 'src/components/Modal/ModalBuyNft';

type Props = {
    name: {
        firstName: string;
        lastName: string;
        username: string;
    };
    id: number;
};

function CreatorNftsList({ name, id }: Props) {
    const theme = useTheme();
    const [nftsList, setNftsList] = useState<Array<INft>>([]);
    const authState = useSelector((state: RootState) => state.auth);
    const [isOpenModalBuy, setOpenModalBuy] = useState<boolean>(false);
    const [modalData, setModalData] = useState<INft>({});

    useEffect(() => {
        (async () => {
            try {
                if (id) {
                    const res = await creatorApi.nftsListCreator(id);
                    if (res.status === 200) {
                        setNftsList(res.data.data);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [id]);

    return (
        <div className="creator-nfts">
            <Container>
                <>
                    <div className="creator-nfts__heading">
                        <h1>{name.firstName}&apos;s</h1>
                        <p>FOR SALE</p>
                    </div>

                    <div className="creator-nfts__nfts">
                        {nftsList.length ? (
                            nftsList.map((item: INft) => {
                                return item.metaData ? (
                                    <Box
                                        key={item.id}
                                        className="creator-nfts__nfts-item"
                                        onClick={() => {
                                            if (!Object.entries(authState?.currentUser || {}).length) {
                                                onMoveAnimation('authenticate-form', 'moveInOpacity');
                                            } else {
                                                setModalData(item);
                                                setOpenModalBuy(true);
                                            }
                                        }}
                                    >
                                        <Box
                                            className="creator-nfts__nfts-item-child"
                                            sx={{
                                                backgroundImage: `url(${item.cover || item.metaData})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'inline-block',
                                                    width: '100%',
                                                    height: '100%',
                                                    transition: 'all 0.3s',
                                                    '&:hover': {
                                                        background:
                                                            'linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(22, 22, 26, 0.7) 95%)',
                                                    },
                                                }}
                                            >
                                                {(item?.fileExtension === 'mp3' || item?.fileExtension === 'mp4') && (
                                                    <Box
                                                        sx={{
                                                            backgroundImage: `url(${
                                                                item?.fileExtension === 'mp3' ? musicIcon : videoIcon
                                                            })`,
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center',
                                                            width: '25px',
                                                            height: '25px',
                                                            border: `1px solid ${theme.color.textWhite}`,
                                                            borderRadius: '8px',
                                                            mt: '10px',
                                                            ml: '10px',
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                ) : (
                                    <img src={comingoonDropImg} alt="comingsoon-drops" />
                                );
                            })
                        ) : (
                            <Box sx={{ color: theme.color.main, fontSize: 30, fontWeight: 700, margin: 'auto' }}>
                                No NFTs for sale yet
                            </Box>
                        )}
                    </div>
                </>
            </Container>
            {isOpenModalBuy && (
                <ModalBuyNft
                    isOpen={isOpenModalBuy}
                    handleClose={() => setOpenModalBuy(false)}
                    modalData={modalData}
                    creator={name}
                />
            )}
        </div>
    );
}

export default CreatorNftsList;
