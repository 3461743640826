import React from 'react';
import { UseWalletProvider } from 'use-wallet';
import { ToastContainer } from 'react-toastify';
import rpc from 'src/configs/Connect/Configs.rpc';
import { useLocation } from 'react-router-dom';
import { RoutesPage } from './Routes';
import { SUPPORTED_CHAINID } from './configs/Connect/Configs.networks';
import { ChainId } from './configs/Connect/Configs.chainId';
import { ConsumeComponent } from './ConsumeComponents';
import DefaultLayout from './components/Layout/DefaultLayout';
import CreatorNotFound from './pages/CreatorNotFound';

function App() {
    const location = useLocation();
    const Layout = location.pathname === '/creatorNotFound' ? (CreatorNotFound.Layout as any) : DefaultLayout;

    return (
        <UseWalletProvider
            autoConnect
            connectors={{
                injected: {
                    // allows you to connect and switch between mainnet and rinkeby within Metamask.
                    chainId: Object.values(ChainId),
                },
                fortmatic: {
                    chainId: SUPPORTED_CHAINID,
                    apiKey: '',
                },
                portis: {
                    dAppId: '',
                    chainId: SUPPORTED_CHAINID,
                },
                walletconnect: {
                    rpc,
                    bridge: 'https://bridge.walletconnect.org',
                    pollingInterval: 12000,
                },
                walletlink: {
                    chainId: [1, 56],
                    url: 'https://eth-mainnet.alchemyapi.io/v2/q1gSNoSMEzJms47Qn93f9-9Xg5clkmEC',
                },
            }}
        >
            <Layout>
                <ConsumeComponent />
                <RoutesPage />
            </Layout>
            <ToastContainer
                position="bottom-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </UseWalletProvider>
    );
}

export default App;
