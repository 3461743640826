/* eslint-disable no-param-reassign */
// we will use this file later
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { InitialStatetype } from './types';
import userApi from '../../api/user';

const initialState: InitialStatetype = {
    user: {},
    isLoading: false,
};

export const getUser = createAsyncThunk('Profile/profile', async () => {
    try {
        const res = await userApi.getUserProfile();
        return res;
    }
    catch (err) {
        console.log('get user profile error', err);
    }
})

export const saveWallet = createAsyncThunk('Profile/saveWallet', async (data: { address: any }) => {
    try {
        const res = await userApi.saveUserWallet(data);
        return res;
    }
    catch (err) {
        console.log('save wallet error', err);
    }
})

export const ProfileSlice = createSlice({
    name: 'Profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUser.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(getUser.fulfilled, (state, action) => {
            state.isLoading = false;
            if (action.payload?.status === 401) {
                state.user = {};
                localStorage.removeItem('access_token');
            }
            if (action.payload.status === 200) {
                state.user = action.payload?.data?.data;
            }
        });

        builder.addCase(getUser.rejected, (state, action) => {
            state.isLoading = false;
            console.log('get user rejected', action);
        });

        builder
            .addCase(saveWallet.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(saveWallet.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(saveWallet.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const userSelector = (state: RootState) => state.profile.user;

export default ProfileSlice.reducer;
