import { parseUnits } from '@ethersproject/units';
import DoneIcon from '@mui/icons-material/Done';
import { Box } from '@mui/material';
import BigNumber from 'bignumber.js';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RootState } from 'src/app/store';
import { transactionActions } from 'src/app/transaction/reducer';
import firstDropImg from 'src/assets/images/home/drops_1.png';
import secondDropImg from 'src/assets/images/home/drops_2.png';
import thirdDropImg from 'src/assets/images/home/drops_3.png';
import cardVIPImg from 'src/assets/images/vip-sm.png';
import collectorAccessPassImg from 'src/assets/images/vip.png';
import { ChainId } from 'src/configs/Connect/Configs.chainId';
import BADGES_ABI from 'src/constants/abis/badges-abi.json';
import ERC20_ABI from 'src/constants/abis/erc20-abi.json';
import { BADGES_CONTRACT, EAP_TOKEN_ADDRESS, HOT_DROPS_CONTRACT } from 'src/constants/addresses';
import { DEFAULT_CHAIN_ID } from 'src/constants/chainId';
import { getUser, saveWallet, userSelector } from 'src/pages/Profile/slice';
import useContract from 'src/services/hooks/useContract';
import { onMoveAnimation } from 'src/services/hooks/useDevelopUI';
import { useScrollToTop } from 'src/services/hooks/useScrollToTop';
import { formatBalance } from 'src/services/utils/format';
import { theme } from 'src/styles/themes';
import { GetAccessPassStatus } from 'src/types/status';
import { useWallet } from 'use-wallet';
import { AppButton } from '../Base';
import { Container } from '../Container';
import { ProcessGetAccessPassModal } from './StatusModal';
import './styles.scss';

export function GetAccessPass() {
    useScrollToTop();
    const dispatch = useDispatch();
    const { account, chainId, connector } = useWallet();
    const authSelector = useSelector((state: RootState) => state.auth);
    const transactionState = useSelector((state: RootState) => state.transaction);

    const profile = useSelector(userSelector);
    const [getAccessPassStatus, setGetAccessPassStatus] = useState<GetAccessPassStatus>('idle');
    const [isOpenGetAccessPassStatusModal, setIsOpenGetAccessPassStatusModal] = useState(false);
    const viewBenefitsRef = useRef<null | HTMLSpanElement>(null);
    const { state } = useLocation();
    const [isWrongNetwork, setIsWrongNetwork] = useState(false);

    const badges1155Contract = useContract(BADGES_CONTRACT[chainId || DEFAULT_CHAIN_ID], BADGES_ABI);
    const eapTokenContract = useContract(EAP_TOKEN_ADDRESS[chainId || DEFAULT_CHAIN_ID], ERC20_ABI);
    const [eapTokenAllowance, setEapTokenAllowance] = useState('0');
    const [eapTokenDecimals, setEapTokenDecimals] = useState<number>();
    const [isPendingCheckEap, setIsPendingCheckEap] = useState(false);
    const [isFetchingTokenAllowance, setIsFetchingTokenAllowance] = useState(false);
    const [isFetchingEapTokenDecimals, setIsFetchingEapTokenDecimals] = useState(false);

    useEffect(() => {
        setIsOpenGetAccessPassStatusModal(false);
        setGetAccessPassStatus('idle');
    }, [account, chainId]);

    useEffect(() => {
        (async () => {
            if (!eapTokenContract) {
                return;
            }

            try {
                setIsFetchingEapTokenDecimals(true);
                const decimals = await eapTokenContract.decimals();
                setEapTokenDecimals(decimals);
                setIsFetchingEapTokenDecimals(false);
            } catch (error) {
                setIsFetchingEapTokenDecimals(false);
                console.error(error);
            }
        })();
    }, [eapTokenContract]);

    useEffect(() => {
        (async () => {
            if (
                !eapTokenDecimals ||
                !eapTokenContract ||
                !account ||
                !chainId ||
                transactionState.approval?.approveStatus === 'Approving'
            ) {
                return;
            }

            try {
                setIsFetchingTokenAllowance(true);
                const allowanceInUnit256 = await eapTokenContract.allowance(account, HOT_DROPS_CONTRACT[chainId]);
                const allowance = formatBalance(allowanceInUnit256, eapTokenDecimals);
                setEapTokenAllowance(allowance);
                setIsFetchingTokenAllowance(false);
            } catch (error) {
                setIsFetchingTokenAllowance(false);
                console.error(error);
            }
        })();
    }, [eapTokenContract, account, chainId, eapTokenDecimals, transactionState.approval?.approveStatus]);

    const approve = useCallback(() => {
        dispatch(
            transactionActions.approveToken({
                spender: HOT_DROPS_CONTRACT[chainId || DEFAULT_CHAIN_ID],
                token: EAP_TOKEN_ADDRESS[chainId || DEFAULT_CHAIN_ID],
                value: '',
            }),
        );
    }, [dispatch, chainId]);

    const saveUserWallet = () => {
        const isOwnWallet = profile?.wallets?.find((el: any) => el?.address === account?.toLowerCase());
        if (account && !isOwnWallet) {
            dispatch(saveWallet({ address: account }));
            dispatch(getUser());
        }
    };

    useEffect(() => {
        saveUserWallet();
        dispatch(getUser());
    }, [account]);

    useEffect(() => {
        (async () => {
            if (!badges1155Contract || transactionState.isPending === true) {
                return;
            }

            try {
                setIsPendingCheckEap(true);
                const isEap = await badges1155Contract.balanceOf(account, transactionState.orderBadgesCurrent?.tokenId);
                if (!new BigNumber(isEap._hex).isZero()) {
                    setGetAccessPassStatus('success');
                }
                setIsPendingCheckEap(false);
            } catch (error) {
                setIsPendingCheckEap(false);
                console.error(error);
            }
        })();
    }, [account, badges1155Contract, transactionState.isPending]);

    useEffect(() => {
        if (
            account &&
            chainId &&
            (process.env.REACT_APP_IS_PROD ? chainId !== ChainId.MAINNET : chainId !== ChainId.RINKEBY)
        ) {
            setIsWrongNetwork(true);
        } else {
            setIsWrongNetwork(false);
        }
    }, [account, chainId]);

    useEffect(() => {
        if (
            state === 'view-benefits' &&
            !isPendingCheckEap &&
            !isFetchingEapTokenDecimals &&
            !isFetchingTokenAllowance
        ) {
            if (!viewBenefitsRef || !viewBenefitsRef.current) {
                return;
            }

            viewBenefitsRef.current.scrollIntoView();
        }
    }, [state, isPendingCheckEap, isFetchingEapTokenDecimals, isFetchingTokenAllowance]);

    useEffect(() => {
        if (transactionState.callback) {
            transactionState.callback();
        }
    }, [transactionState.callback]);

    const handleGetAccessPass = async () => {
        if (
            !eapTokenContract ||
            !account ||
            (connector !== 'walletconnect' && !window.ethereum) ||
            getAccessPassStatus === 'success'
        ) {
            return;
        }

        if (new BigNumber(eapTokenAllowance).lt(transactionState.orderBadgesCurrent?.price)) {
            approve();
            return;
        }

        try {
            const [decimals, balance] = await Promise.all([
                eapTokenContract.decimals(),
                eapTokenContract.balanceOf(account),
            ]);
            if (parseUnits(String(transactionState.orderBadgesCurrent?.price), decimals).gt(balance)) {
                toast.error("You don't have enough balance to execute the transaction.", {
                    position: 'top-center',
                });
                return;
            }
            setGetAccessPassStatus('pending');
            setIsOpenGetAccessPassStatusModal(true);
            dispatch(
                transactionActions.handleBuyBadges({
                    orderIndex: String(transactionState.orderBadgesCurrent?.orderId),
                    quantity: '1',
                    callback: (accessPassStatus: GetAccessPassStatus) => {
                        setGetAccessPassStatus(accessPassStatus);
                        if (accessPassStatus === 'error') {
                            setIsOpenGetAccessPassStatusModal(false);
                        }
                    },
                }),
            );
        } catch (error) {
            console.error(error);
        }
    };

    const getBuyEapSection = () => {
        if (getAccessPassStatus === 'success') {
            return (
                <div className="eap__vip">
                    <img src={cardVIPImg} alt="card-vip-img" />
                    <span className="title">You are a VIP Member!</span>
                    <span className="content" ref={viewBenefitsRef}>
                        As an Early Access Pass holder you will have exclusive member-only benefits and early access to
                        the hottest drops.
                    </span>
                </div>
            );
        }

        return (
            <>
                <div className="eap__hero">
                    <button type="button" className="eap__status">
                        Available Now
                    </button>
                    <h1 className="eap__heading">COLLECTOR ACCESS PASS</h1>
                    <img src={collectorAccessPassImg} alt="collector-access-pass-img" />
                </div>

                <Box className="eap__btn-buy">
                    <AppButton
                        disabled={
                            isWrongNetwork ||
                            (transactionState.approval?.token === EAP_TOKEN_ADDRESS[chainId || DEFAULT_CHAIN_ID] &&
                                transactionState.approval?.approveStatus === 'Approving')
                        }
                        onClick={() =>
                            !authSelector?.currentUser?.id
                                ? onMoveAnimation('authenticate-form', 'moveInOpacity')
                                : account
                                ? handleGetAccessPass()
                                : onMoveAnimation('connect-modal', 'moveInOpacity')
                        }
                        sx={{
                            borderRadius: '999px',
                            padding: '17px 121px',
                            whiteSpace: 'nowrap',
                            [theme.breakpoints.down(576)]: {
                                padding: '16px 115px',
                                fontSize: '16px',
                            },
                        }}
                    >
                        <span ref={viewBenefitsRef}>
                            {account
                                ? new BigNumber(eapTokenAllowance).lt(transactionState.orderBadgesCurrent?.price)
                                    ? transactionState.approval?.token ===
                                          EAP_TOKEN_ADDRESS[chainId || DEFAULT_CHAIN_ID] &&
                                      transactionState.approval?.approveStatus === 'Approving'
                                        ? 'Approving ...'
                                        : 'Approve USDT'
                                    : `BUY (${transactionState.orderBadgesCurrent?.price} USDT)`
                                : 'Connect Wallet'}
                        </span>
                    </AppButton>
                </Box>
            </>
        );
    };

    return (
        <>
            <div className="eap">
                <Container>
                    {isPendingCheckEap || isFetchingTokenAllowance || isFetchingEapTokenDecimals ? (
                        <div className="eap__loading" />
                    ) : (
                        <>
                            {getBuyEapSection()}

                            <div className="eap__benefits">
                                <h2>Benefits</h2>

                                <div className="benefits">
                                    <div className="benefits-item">
                                        <DoneIcon /> <span>10% off all NFT purchases</span>
                                    </div>
                                    <div className="benefits-item">
                                        <DoneIcon /> <span>Become a top collector</span>
                                    </div>
                                    <div className="benefits-item">
                                        <DoneIcon /> <span>1 year membership</span>
                                    </div>
                                    <div className="benefits-item">
                                        <DoneIcon /> <span>Early access to select Drops</span>
                                    </div>
                                </div>
                            </div>

                            <div className="eap__posters">
                                <h2>10% OFF. 1 YEAR. UNLIMITED.</h2>

                                <h3>10% OFF.</h3>
                                <h3>1 YEAR.</h3>
                                <h3>UNLIMITED.</h3>

                                <div className="posters">
                                    <div className="posters-item">
                                        <img src={firstDropImg} alt="first-drop-img" />
                                    </div>
                                    <div className="posters-item">
                                        <img src={secondDropImg} alt="second-drop-img" />
                                    </div>
                                    <div className="posters-item">
                                        <img src={thirdDropImg} alt="third-drop-img" />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </Container>
            </div>

            {isOpenGetAccessPassStatusModal && (
                <ProcessGetAccessPassModal
                    getAccessPassStatus={getAccessPassStatus}
                    onCloseModal={() => {
                        dispatch(transactionActions.cancelCallback());
                        setIsOpenGetAccessPassStatusModal(false);
                    }}
                />
            )}
        </>
    );
}
