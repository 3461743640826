import { BigNumber, ethers } from 'ethers';
import { BigNumber as BN } from 'bignumber.js';
import { parseUnits } from '@ethersproject/units';
import { DEFAULT_DECIMAL } from 'src/constants/chainId';

export const toFixedDecimal = (val: string | number, decimals = DEFAULT_DECIMAL): string => {
    const split = val.toString().split('.');
    if (split.length > 1) {
        return decimals ? `${split[0]}.${split[1].substr(0, decimals)}` : split[0];
    }

    return val.toString();
};

export const formatBalance = (value: ethers.BigNumberish, decimals = DEFAULT_DECIMAL, maxFraction = 0) => {
    const formatted = ethers.utils.formatUnits(
        Number(value) ? toFixedDecimal(new BN(value.toString()).toFixed(), 0) : BigNumber.from('0'),
        decimals,
    );
    if (maxFraction > 0) {
        const split = formatted.split('.');
        if (split.length > 1) {
            return `${split[0]}.${split[1].substr(0, maxFraction)}`;
        }
    }
    return formatted;
};

export const parseBalance = (value: string, decimals = DEFAULT_DECIMAL): BigNumber => {
    return parseUnits(value || '0', decimals);
};

export const shortenAddress = (address: string) => {
    return `${address.slice(0, 7)}...${address.slice(-4)}`;
};

export const handleTime = (seconds: number) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds - h * 3600) / 60);
    const s = Math.floor(seconds - h * 3600 - m * 60);
    return `${h <= 9 ? '0' : ''}${h}:${m <= 9 ? '0' : ''}${m}:${s <= 9 ? '0' : ''}${s}`;
};
