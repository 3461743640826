export const listButton = [
    'Dirty Dozen',
    'Influencers',
    'Models',
    'Directors',
    'Photographers',
    'Artists',
    'Studios',
    'Brands',
    'Celebrity',
    'Athlete',
    'Musician',
    'Gamer',
    'Streamer',
    'Podcaster',
    'Blogger',
];
export const listButtonCreators = [
    'Modals',
    'Actors',
    'Artists',
    'Photographers',
    'Studios',
    'Brands',
    'Directors',
    'Influencers',
    'SFW',
    'NSFW',
    'Genre',
    'Industry',
    'Female',
    'Male',
    'Trans',
    'Non-Binary',
    'Gay',
    'Lesbian',
];
export const listHottiesItem = [
    {
        name: 'Brandi Love',
        image: '1_Favorite Hotties_Brandi',
    },
    {
        name: 'Dani Daniels',
        image: '1_Favorite Hotties_Dani',
    },
    {
        name: 'Cherie DeVille',
        image: '1_Favorite Hotties_Cherie',
    },
    {
        name: 'Elsa Jean',
        image: '1_Favorite Hotties_Elsa',
    },

    {
        name: 'Eva Lovia',
        image: '1_Favorite Hotties_Eva',
    },

    {
        name: 'Kenna James',
        image: '1_Favorite Hotties_Kenna',
    },
    {
        name: 'Alison Tyler',
        image: '1_Favorite Hotties_Alison',
    },
    {
        name: 'Alex Coal',
        image: '1_Favorite Hotties_Alex',
    },

    {
        name: 'Reagan Foxx',
        image: '1_Favorite Hotties_Reagan',
    },

    {
        name: 'Joanna Angel',
        image: '1_Favorite Hotties_Joanna',
    },
    {
        name: 'Lauren',
        image: '1_Favorite Hotties_Lauren',
    },
];
