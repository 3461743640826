import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const BASE_URL = process.env.REACT_APP_API_SERVER_ENDPOINT;

type Item = {
    username?: string;
    image: string;
    name: string;
};

export function HottiesItem({ username, image, name }: Item) {

    return (
        <div className="hotties-item-container">
            <Link to={`/creators/${username}`}>
                <img className="avatar" src={image?.indexOf('http') === 0 ? image : `${BASE_URL}${image}`} alt="" />
            </Link>
            <Link to={`/creators/${username}`}>
                <div className="name" title={name}>{name}</div>
            </Link>
        </div>
    );
}
