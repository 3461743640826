import React from 'react';
import { useConnectWallet } from 'src/services/hooks/useWalletProviders';
import { onMoveAnimation } from 'src/services/hooks/useDevelopUI';
import './styles.scss';

export function ConnectModal() {
    const activate = useConnectWallet();
    return (
        <div id="connect-modal" className="connect-modal">
            <div onClick={() => onMoveAnimation('connect-modal', 'moveOutOpacity')} className="overlay" />
            <div className="connect-modal__form">
                {/* <span className="subtitle">Choose Network</span>
                <div className="child__container">
                    <div
                        onClick={() => {
                            setActive(0);
                            data?.setChainId(1);
                        }}
                        className={`network-container ${active === 0 ? 'active' : ''}`}
                    >
                        <img src="https://infinitelaunch.io/static/media/ethereum.2ce244ef.svg" alt="Ethereum" />
                        <span>Ethereum</span>
                    </div>
                    <div
                        onClick={() => {
                            setActive(1);
                            data?.setChainId(56);
                        }}
                        className={`network-container ${active === 1 ? 'active' : ''}`}
                    >
                        <img src="https://infinitelaunch.io/static/media/bsc.813c8209.svg" alt="Binance" />
                        <span>Binance</span>
                    </div>
                </div> */}
                <span className="subtitle">Choose Wallet</span>
                <div className="child__container">
                    <div onClick={() => activate(0, 'injected')} className="wallet-container">
                        <img src="https://infinitelaunch.io/static/media/metamask.fac5fa65.svg" alt="Metamask" />
                        <span>Metamask</span>
                    </div>
                    <div onClick={() => activate(0, 'walletconnect')} className="wallet-container">
                        <img
                            src="https://infinitelaunch.io/static/media/walletconnect.aa6c9f2b.svg"
                            alt="Walletconnect"
                        />
                        <span>Walletconnect</span>
                    </div>
                    <div className="wallet-container">
                        <img src="https://infinitelaunch.io/static/media/coinbase.44ebfa31.svg" alt="Walletconnect" />
                        <span>Coinbase</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
