/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import './styles.scss';
import { IDataTrending } from "src/api/type";
import masterApi from '../../api/master';

const BASE_URL = process.env.REACT_APP_API_SERVER_ENDPOINT;

const searchOptions = [
    { id: 1, name: 'All', value: '' },
    { id: 2, name: 'Creators', value: 'creators' },
    { id: 3, name: 'Categories', value: 'categories' },
    { id: 4, name: 'Tags', value: 'tags' },
];

export function SearchHeader() {
    const [showIcon, setShowIcon] = useState(false);
    const [selectedSearch, setSelectedSearch] = useState({
        ...searchOptions[0],
    });
    const [searchResults, setSearchResults] = useState<any>({
        creators: [],
        categories: [],
        tags: [],
    });
    const ref = useRef<any>(null);
    const inputRef = useRef<any>(null);
    const [searchKey, setSearchKey] = useState('');

    const onChangeSearchKey = async (e: any) => {
        if (!e.target.value) {
            setSearchResults({
                creators: [],
                categories: [],
                tags: [],
            });
            setSearchKey('');
        } else {
            const res = await masterApi.handleSearch(e.target.value);
            const creators = res.creators.map((creator) => {
                return {
                    key: 'creators',
                    name: `${creator?.firstName} ${creator?.lastName}`,
                    image: creator.avatar,
                    username: creator.username,
                    type: creator.type,
                };
            });

            const tags = res.tags.map((tag) => {
                return {
                    key: 'tags',
                    name: tag.name,
                    // image: tag.avatar,
                    username: tag.name,
                    type: tag.type,
                };
            });

            const categories = res.categories.map((category) => {
                return {
                    key: 'categories',
                    name: category.name,
                    // image: category.avatar,
                    username: category.name,
                    type: category.type,
                };
            });
            setSearchResults({
                categories,
                creators,
                tags,
            });
            setSearchKey(e.target.value);
        }
    };

    const handleClickSearch = async (data: IDataTrending) => {
        try {
            const res = masterApi.searchTrending(data);
            console.log(res);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current?.contains(event.target)) {
                setShowIcon(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset !== 0) {
                setShowIcon(false);
                inputRef.current.blur();
            }
            // else if (window.pageYOffset === 0 && searchKey) {
            //     setShowIcon(true);
            //     inputRef.current.focus();
            // }
        };
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [searchKey]);

    return (
        <div className={showIcon ? 'search-header-container expand' : 'search-header-container'} ref={ref}>
            <div className="search-header">
                <SearchIcon className="search-icon" />
                <input
                    type="text"
                    placeholder="Search"
                    autoComplete="off"
                    ref={inputRef}
                    onChange={onChangeSearchKey}
                    onFocus={() => setShowIcon(true)}
                />
                {showIcon && <HighlightOffIcon className="clear-icon" onClick={() => setShowIcon(false)} />}
            </div>
            {showIcon && (
                <div className="search-header-dropdown">
                    <div className="search-header-dropdown__container">
                        <div className="search-option-list">
                            {searchOptions.map((item) => (
                                <div
                                    className={selectedSearch.id === item.id ? 'search-option active' : 'search-option'}
                                    key={item.id}
                                    onClick={() => setSelectedSearch(item)}
                                >
                                    {item.name}
                                </div>
                            ))}
                        </div>
                        <div className="search-result-list">
                            {(selectedSearch.value
                                ? searchResults[selectedSearch.value]
                                : [...searchResults.creators, ...searchResults.categories, ...searchResults.tags]
                            )?.map((item: any, index: number) => (
                                <Link
                                    to={item.key === 'creators' ? `/creators/${item?.username}` : '/'}
                                    className="search-result-list__item"
                                    key={index}
                                    onClick={() => {
                                        handleClickSearch({
                                            keyword: item?.name,
                                            type: item.type,
                                        });
                                        setShowIcon(false);
                                    }}
                                >
                                    {item?.key === 'creators' && (
                                        <img
                                            src={`${item?.image?.indexOf('http') === 0 ? '' : BASE_URL}${item?.image}`}
                                            alt=""
                                        />
                                    )}
                                    <div className="search-result-name">{`${item?.key === 'tags' ? "#" : ""}${item?.name}`}</div>
                                </Link>
                            ))}
                        </div>
                    </div>
                    <div className="gap" />
                </div>
            )}
        </div>
    );
}
