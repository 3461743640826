import React from 'react';
import './styles.scss';

export function Container({ children }: { children: JSX.Element }) {
    return (
        <div className="container">
            <div className="container__content">{children}</div>
        </div>
    );
}
