import { Box } from '@mui/material';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppButton } from 'src/components';

function CreatorNotFound() {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <h2>404 - Page Not Found</h2>
            <Box sx={{ width: 'fit-content', m: '20px', fontSize: '30px', fontWeight: 700 }}>
                The creator does not exist
            </Box>
            <AppButton sx={{ width: 'fit-content' }} onClick={() => navigate('/')}>
                Home
            </AppButton>
        </Box>
    );
}

CreatorNotFound.Layout = ({ children }: { children: ReactNode }) => {
    return children;
};

export default CreatorNotFound;
