import React from 'react';
import './styles.scss';

type Reason = {
    title: string;
    description: string;
};
export function ReasonItem({ title, description }: Reason) {
    return (
        <div className="reason-item-container">
            <div className="reason-title">{title}</div>
            <hr className="hr-reason" />
            <div className="reason-description">{description}</div>
        </div>
    );
}
