/* eslint-disable react/no-array-index-key */
import { Box, useTheme } from '@mui/material';
import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { nftApi } from 'src/api/nft';
import musicIcon from 'src/assets/icons/music-icon.svg';
import collectionImage from 'src/assets/images/wallet-logos/collection.svg';
import walletImage from 'src/assets/images/wallet-logos/wallet-account.svg';
import { AppButton } from 'src/components';
import ModalBuyNft from "src/components/Modal/ModalBuyNft";
import { formatBalance, shortenAddress } from 'src/services/utils/format';

const initInfo = [
    {
        name: 'Creator',
        image: walletImage,
        value: shortenAddress(ethers.constants.AddressZero),
    },
    {
        name: 'Collection',
        image: collectionImage,
        value: '',
    },
];

function NftDetails() {
    const theme = useTheme();
    const { pathname } = useLocation();
    const [nftDetails, setNftDetails] = useState<any>();
    const [nftInfo, setNftInfo] = useState(initInfo);
    const [isOpenModalBuy, setIsOpenModalBuy] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            try {
                const res = await nftApi.nftDetails(pathname.split('/')[2]);
                if (res.status === 200) {
                    setNftDetails(res.data.data);
                    const info = [shortenAddress(res.data.data.address), res.data.data.collection.name];

                    setNftInfo(() => {
                        return initInfo.map((item, index) => {
                            return {
                                ...item,
                                value: info[index],
                            };
                        });
                    });
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [pathname]);

    return (
        <Box sx={{ p: '40px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ width: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    {nftDetails?.fileExtension !== 'mp4' && (
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url(${nftDetails?.cover || nftDetails?.metaData})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                mb: nftDetails?.fileExtension === 'mp3' ? '20px' : ''
                            }}
                        >
                            {nftDetails?.fileExtension === 'mp3' && (
                                <Box
                                    sx={{
                                        display: 'inline-block',
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '15px',
                                        background:
                                            'linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(22, 22, 26, 0.7) 95%)',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundImage: `url(${musicIcon})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            position: 'absolute',
                                            width: '25px',
                                            height: '25px',
                                            border: `1px solid ${theme.color.textWhite}`,
                                            borderRadius: '8px',
                                            mt: '10px',
                                            ml: '10px',
                                        }}
                                    />
                                </Box>
                            )}
                        </Box>
                    )}
                    {
                        nftDetails?.fileExtension === 'mp3' && (
                            <audio src={nftDetails?.metaData} autoPlay controls />
                        )
                    }
                    {
                        nftDetails?.fileExtension === 'mp4' && (
                            <video src={nftDetails?.metaData} autoPlay controls width="100%" />
                        )
                    }
                </Box>
                <Box sx={{ paddingLeft: '64px', width: '60%' }}>
                    <h2>{nftDetails?.name}</h2>
                    <Box sx={{ mt: '12px', color: theme.color.text6, fontSize: '14px', lineHeight: '21px' }}>
                        From{' '}
                        <Box sx={{ fontWeight: 700, color: theme.color.textWhite }} component="span">
                            {formatBalance(nftDetails?.price)} USDT
                        </Box>
                        <Box component="span" sx={{ mx: '4px', fontWeight: 700 }}>
                            ·
                        </Box>
                        <Box component="span">1 of 1 available</Box>
                    </Box>
                    <Box sx={{ mt: '24px', color: theme.color.text6 }}>
                        {nftDetails?.description}{' '}
                        <Box component="span" sx={{ fontWeight: 700, cursor: 'pointer' }}>
                            Read more
                        </Box>
                    </Box>
                    <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
                        {nftInfo.map((item, index: number) => {
                            return (
                                <Box sx={{ mt: '24px' }} key={index}>
                                    <Box
                                        sx={{
                                            fontWeight: 600,
                                            color: theme.color.text6,
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                        }}
                                    >
                                        {item.name}
                                    </Box>
                                    <Box
                                        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '12px' }}
                                    >
                                        <img src={item.image} alt="" height={40} width={40} />
                                        <Box
                                            sx={{
                                                fontWeight: 'bold',
                                                marginLeft: '16px',
                                                fontSize: '14px',
                                                lineHeight: '21px',
                                            }}
                                        >
                                            {item.value}
                                        </Box>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                    <Box sx={{ mt: '40px' }}>
                        <AppButton 
                            sx={{ 
                                borderRadius: '50px', 
                                padding: '12px 40px' 
                            }}
                            onClick={() => setIsOpenModalBuy(true)}
                        >
                            Buy NFT
                        </AppButton>
                    </Box>
                </Box>
            </Box>
            {
                isOpenModalBuy && (
                    <ModalBuyNft 
                        isOpen={isOpenModalBuy}
                        handleClose={() => setIsOpenModalBuy(false)}
                        creator={nftDetails?.createdByCreator}
                        modalData={nftDetails}
                    />
                )
            }
        </Box>
    );
}

export default NftDetails;
