import React, { useEffect, useState } from 'react';
import './styles.scss';
import { AccessTime } from '@material-ui/icons';
import { Creator } from 'src/types/creators';
import creatorApi from 'src/api/creator';
import { listButton } from './hard';
import { HottiesItem } from './hotties-item';

export function FollowHotties() {
    const [creators, setCreators] = useState<Creator[]>([]);
    const [selectedCategory, setSelectedCategory] = useState('Dirty Dozen');

    const fetchCreators = async () => {
        const creatorsResponse = await creatorApi.fetchCreators();
        setCreators(creatorsResponse);
    };

    const selectCategory = (category: string) => {
        setSelectedCategory(category);
    };

    useEffect(() => {
        fetchCreators();
    }, []);

    return (
        <div className="follow-container">
            <div className="follow-container__title">
                <span className="follow-container__title top">
                    FOLLOW <br />
                </span>
                <span className="follow-container__title bottom">YOUR FAVORITE HOTTIES </span>
            </div>
            <div className="follow-container__hotties-wrapper">
                {creators?.map((creator) => (
                    <HottiesItem
                        username={creator.username}
                        name={`${creator.firstName} ${creator.lastName}`}
                        image={creator.avatar}
                        key={creator.id}
                    />
                ))}
            </div>
            <div className="follow-container__button-rows">
                {listButton.map(function producer(element) {
                    return (
                        <button
                            className={selectedCategory === element ? 'btn-item active' : 'btn-item'}
                            key={element}
                            type="button"
                            onClick={() => selectCategory(element)}
                        >
                            {element}
                        </button>
                    );
                })}
            </div>
            <div className="follow-container__commingsoon">
                <AccessTime className="icon-commingsoon" />
                NEW CATEGORIES COMING SOON
            </div>
        </div>
    );
}
