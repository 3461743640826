import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { GetAccessPass } from './components/GetAccessPass';
import { ComingPage } from './pages/Coming/loadable';
import CreatorNotFound from './pages/CreatorNotFound';
import { CreatorDetails } from './pages/Creators/components/CreatorDetails';
import NftDetails from './pages/Nft/NftDetails';
import { CreatorsPage } from './pages/Creators/loadable';
import Example from './pages/Example';
import { HomePage } from './pages/Home/loadable';
import { ProfilePage } from './pages/Profile/loadable';

export function RoutesPage() {
    return (
        <Routes>
            <Route path="*" element={<ComingPage />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/creators" element={<CreatorsPage />} />
            <Route path="/creators/:username" element={<CreatorDetails />} />
            <Route path="/nft/:id" element={<NftDetails />} />
            <Route path="/eap" element={<GetAccessPass />} />
            <Route path="/example" element={<Example />} />
            <Route path="/creatorNotFound" element={<CreatorNotFound />} />
        </Routes>
    );
}
