import React from 'react';
import './styles.scss';
import { SearchHeader } from '../SearchHeader';

export function SearchMobile() {
    return (
        <div className="search-mobile-container">
            <SearchHeader />
        </div>
    );
}
