import React from 'react';
import './styles.scss';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { onMoveAnimation } from 'src/services/hooks/useDevelopUI';
import { AppButton } from 'src/components';
import { RootState } from '../../../../app/store';
import EarlyAdopter from '../../../../assets/images/home/early-adopter.gif';
import AccessPass from '../../../../assets/images/home/access-pass.gif';

export function Begin() {
    const authSelector = useSelector((state: RootState) => state.auth);

    return (
        <div className="begin-container">
            <div className="begin-left">
                <h1>
                    THE HOTTEST <br /> DIGITAL COLLECTIBLES
                </h1>
                <span className="description">
                    The inclusive NFT marketplace disrupting how creators and fans connect with next-gen content,
                    exclusive, limited-edition digital collectibles, community, and loyalty rewards.
                </span>

                {authSelector?.currentUser?.id ? (
                    <Link to="/eap">
                        <AppButton className="get-access-button">Get Access Pass</AppButton>
                    </Link>
                ) : (
                    <div
                        className="get-access-button"
                        onClick={() => onMoveAnimation('authenticate-form', 'moveInOpacity')}
                    >
                        Get Early Adopter Badge
                    </div>
                )}
            </div>
            <div className="begin-right">
                <img src={authSelector?.currentUser?.id ? AccessPass : EarlyAdopter} alt="" />
                {/* <video controls autoPlay id="vid" muted>
                    <source src={authSelector?.currentUser?.id ? AccessPass : EarlyAdpoterVideo} type="video/mp4" />
                </video> */}
            </div>
        </div>
    );
}
