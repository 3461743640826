import { Button, Theme, useTheme } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { Box } from '@mui/system';
import React, { useCallback } from 'react';

interface IButtonProps extends Omit<ButtonProps, 'color' | 'size'> {
    color?: 'white' | 'primary' | 'black' | 'gradient';
    loading?: boolean;
    size?: 's' | 'm' | 'l' | 'xl';
}

export const AppButton = React.forwardRef(function AppButton(
    {
        color = 'primary',
        children,
        sx,
        variant = 'contained',
        loading = false,
        size = 'm',
        disabled,
        ...props
    }: IButtonProps,
    ref: React.Ref<HTMLButtonElement>,
) {
    const theme = useTheme<Theme>();
    const getStyleBtn = ({ bg, textColor, border }: { bg: string; textColor: string; border: string }) => {
        switch (variant) {
            case 'outlined':
                return {
                    border: `1px solid`,
                    borderColor: theme.color.red,
                    color: textColor,
                    background: 'none',

                    '&&&:hover': {
                        background: 'none',
                    },
                    '&:active': {
                        boxShadow: 'none',
                        borderColor: border,
                    },

                    '&&&:disabled': {
                        color: textColor,
                        opacity: 0.6,
                    },
                };
            case 'text':
                return {};
            default:
                return {
                    height: '50px',
                    padding: '8px',
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    border,
                    color: textColor,
                    background: bg,

                    '&&&:hover': {
                        background: bg,
                        // boxShadow: (theme: Theme) => theme.color.boxShadow2,
                    },
                    '&:active': {
                        boxShadow: 'none',
                        borderColor: border,
                    },

                    '&&&:disabled': {
                        color: textColor,
                        opacity: 0.6,
                    },
                };
        }
    };

    const stylesSize = {
        s: {
            fontSize: '10px',
            padding: '5px 20px',
            borderRadius: '4px',
        },
        m: {
            fontSize: '15px',
            padding: '10px 50px',
        },
        l: {
            fontSize: '16px',
            padding: '15px 70px',
            borderRadius: '100px',
        },
        xl: {
            fontSize: '18px',
            padding: '15px 70px',
            borderRadius: '100px',
        },
    };

    const iconLoading = useCallback(() => {
        let iconSize = {};
        switch (size) {
            case 's':
                iconSize = {
                    width: '10px',
                    height: '10px',
                    minWidth: '10px',
                    minHeight: '10px',
                };
                break;
            case 'm':
                iconSize = {
                    width: '15px',
                    height: '15px',
                    minWidth: '15px',
                    minHeight: '15px',
                };
                break;
            case 'l':
                iconSize = {
                    width: '16px',
                    height: '16px',
                    minWidth: '16px',
                    minHeight: '16px',
                };
                break;
            case 'xl':
                iconSize = {
                    width: '18px',
                    height: '18px',
                    minWidth: '18px',
                    minHeight: '18px',
                };
                break;

            default:
                break;
        }
        return (
            <Box
                sx={{
                    ...iconSize,
                    border: `2px solid ${theme.color.red}`,
                    borderTop: `2px solid ${theme.color.bgBlock}`,
                    animation: 'loading 1s infinite linear',
                    marginRight: '5px',
                    borderRadius: '100%',

                    '@keyframes loading': {
                        from: {
                            transform: 'rotate(0)',
                        },
                        to: {
                            transform: 'rotate(360deg)',
                        },
                    },
                }}
            />
        );
    }, []);

    const style = {
        primary: getStyleBtn({ textColor: theme.color.textWhite, bg: theme.color.bgBtn, border: 'none' }),
        white: getStyleBtn({ textColor: theme.color.textRed, bg: theme.color.bgBlock, border: 'none' }),
        black: getStyleBtn({
            textColor: theme.color.textWhite,
            bg: theme.color.bgBtn2,
            border: `1px solid ${theme.color.border2}`,
        }),
        gradient: getStyleBtn({ textColor: theme.color.textWhite, bg: theme.color.bgBtn3, border: 'none' }),
    };

    return (
        <Button
            ref={ref}
            sx={{
                textTransform: 'none',
                fontWeight: 'bold',
                border: '0px solid',
                lineHeight: '24px',
                borderRadius: '12px',
                ...style[color],
                ...stylesSize[size],
                ...sx,
            }}
            disabled={loading || disabled}
            {...props}
        >
            {color === 'gradient' ? (
                <Box
                    sx={{
                        background: theme.color.bgGradient,
                        backgroundClip: 'text',
                        textFillColor: 'transparent',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                    }}
                >
                    {loading && iconLoading()}
                    {children}
                </Box>
            ) : (
                <>
                    {loading && iconLoading()}
                    {children}
                </>
            )}
        </Button>
    );
});
