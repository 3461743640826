/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import './styles.scss';
import { TrendingCard } from '../TrendingCard/index';
import btnleft from '../../../../assets/images/home/1_Trending Hotties_Left arrow.png';
import btnright from '../../../../assets/images/home/1_Trending Hotties_Right arrow.png';

export function TrendingHotties() {
    const [activeCard, setActiveCard] = React.useState([
        {
            name: 'Dani Daniels',
            image: 'trending-hotties_dani_big',
        },
        {
            name: 'Brandi Love',
            image: 'trending-hotties_brandi_small',
        },
        {
            name: 'Cherie Deville',
            image: 'trending_hotties_cherie_small',
        },
    ]);
    const onNext = () => {
        const state = activeCard;
        const updateInfo = [state[2], state[0], state[1]];
        setActiveCard(updateInfo);
    };
    const onPrevious = () => {
        const state = activeCard;
        const updateInfo = [state[1], state[2], state[0]];
        setActiveCard(updateInfo);
    };
    return (
        <div className="trending-container">
            <h1> TRENDING HOTTIES</h1>
            <div className="trending-container__listHotties">
                {activeCard.map((info, index) => (
                    <div
                        key={info.name}
                        className={`trending-container__listHotties__${
                            index === 0 ? 'left' : index === 1 ? 'middle' : 'right'
                        } item`}
                    >
                        <TrendingCard name={info.name} image={info.image} />
                    </div>
                ))}
            </div>
            <div className="trending-container__buttons">
                {/* <div className="btn-back"> */}
                {/* <ArrowBackIosSharp className="back-icon" /> */}
                <img onClick={() => onPrevious()} src={btnleft} alt="back" className="button-trending-hotties" />
                {/* </div> */}

                {/* <div className="btn-forward"> */}
                {/* <ArrowForwardIos className="forward-icon" /> */}
                <img onClick={() => onNext()} src={btnright} alt="forward" className="button-trending-hotties" />
                {/* </div> */}
            </div>
        </div>
    );
}
