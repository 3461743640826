/* eslint-disable no-param-reassign */
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_CHAIN_ID } from 'src/constants/chainId';
import { ApproveStatus } from '../types';

export interface ITransactionState {
    chainId: string | number;
    account: string | null;
    isPending: boolean;
    orderBadgesCurrent: any;
    approval?: {
        spender?: string;
        approveStatus?: ApproveStatus;
        token?: string;
    };
    callback?: (data?: any) => any;
}

export const initialState: ITransactionState = {
    chainId: DEFAULT_CHAIN_ID,
    account: null,
    isPending: false,
    approval: undefined,
    callback: undefined,
    orderBadgesCurrent: null,
};

const masterWallet: CaseReducer<
    ITransactionState,
    PayloadAction<{ chainId: string | number; provider?: any; account: string | null; orderBadgesCurrent?: any }>
> = (state, action) => {
    state.chainId = action.payload.chainId;
    state.account = action.payload?.account;
    state.orderBadgesCurrent = action.payload?.orderBadgesCurrent;
};

const actionStartTx: CaseReducer<ITransactionState, PayloadAction> = (state) => {
    state.isPending = true;
};

const updateTransaction: CaseReducer<ITransactionState, PayloadAction<{ isPending: boolean }>> = (state, action) => {
    state.isPending = action.payload.isPending;
};

const actionCallback: CaseReducer<ITransactionState, PayloadAction<{ callback: (data?: any) => any }>> = (
    state,
    action,
) => {
    state.callback = action.payload.callback;
};

const actionCancelCallback: CaseReducer<ITransactionState, PayloadAction> = (state) => {
    state.callback = undefined;
};

const actionBuyNft: CaseReducer<
    ITransactionState,
    PayloadAction<{ orderIndex: string; quantity: string; disCode: string; callback?: (data?: any) => any } | undefined>
> = (state, action) => {
    state.callback = action.payload?.callback;
    state.isPending = false;
};

const actionBuyBadges: CaseReducer<
    ITransactionState,
    PayloadAction<
        | {
              orderIndex: string;
              quantity: string;
              callback?: (data?: any) => any;
          }
        | undefined
    >
> = (state, action) => {
    state.callback = undefined;
    state.isPending = false;
    state.callback = action.payload?.callback;
};

const actionApproveToken: CaseReducer<
    ITransactionState,
    PayloadAction<
        | {
              token: string;
              spender: string;
              value: string;
              approveStatus?: ApproveStatus;
              callback?: (data?: any) => any;
          }
        | undefined
    >
> = (state, action) => {
    state.approval = {
        spender: action.payload?.spender,
        approveStatus: action.payload?.approveStatus,
        token: action.payload?.token,
    };
    state.isPending = false;
    state.callback = action.payload?.callback;
};

const actionApproveStatus: CaseReducer<
    ITransactionState,
    PayloadAction<{ approveStatus: ApproveStatus; token: string } | undefined>
> = (state, action) => {
    state.approval = {
        ...state.approval,
        approveStatus: action.payload?.approveStatus,
        token: action.payload?.token,
    };
};

const TransactionSlice = createSlice({
    name: 'Transaction',
    initialState,
    reducers: {
        masterDataWallet: masterWallet,
        handleBuyNft: actionBuyNft,
        handleBuyBadges: actionBuyBadges,
        approveToken: actionApproveToken,
        startTx: actionStartTx,
        updateApproveStatus: actionApproveStatus,
        isPendingTransaction: updateTransaction,
        cancelCallback: actionCancelCallback,
        handleCallback: actionCallback,
    },
});

export const { masterDataWallet } = TransactionSlice.actions;

export const transactionActions = TransactionSlice.actions;

export default TransactionSlice.reducer;
