/* eslint-disable radix */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Container, SearchHeader } from 'src/components';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';
import { onMoveAnimation } from 'src/services/hooks/useDevelopUI';
import { useChangeChain, useDisconnectWallet } from 'src/services/hooks/useWalletProviders';
import { useWallet } from 'use-wallet';
import { ChainId } from 'src/configs/Connect/Configs.chainId';
import { TAB_PROFILE } from 'src/constants/tabProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faTwitch, faDiscord, faReddit, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { RootState } from '../../app/store';
import { menuItemDesktop } from './hard';
import './styles.scss';
import { logOut } from '../AuthenticateModal/slice';
import DefaultAvatar from '../../assets/images/default-avatar.png';

export function Header() {
    const dispatch = useDispatch();
    const authSelector = useSelector((state: RootState) => state.auth);
    const ref = useRef<any>(null);
    const [headerNavMobileOpen, setHeaderNavMobileOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 991);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const disconnect = useDisconnectWallet();
    const { account, chainId } = useWallet();
    const changeNetwork = useChangeChain();
    const [isWrongNetwork, setIsWrongNetwork] = useState(false);
    const [dropdownMenu, setDropdownMenu] = useState(false);

    useEffect(() => {
        if (
            account &&
            chainId &&
            (process.env.REACT_APP_IS_PROD ? chainId !== ChainId.MAINNET : chainId !== ChainId.RINKEBY)
        ) {
            setIsWrongNetwork(true);
        } else {
            setIsWrongNetwork(false);
        }
    }, [account, chainId]);

    const handleLogOut = () => {
        disconnect();
        navigate("/");
        dispatch(logOut());
    };

    const toggleHeaderNavMobile = () => {
        setHeaderNavMobileOpen(!headerNavMobileOpen);
    };

    const toggleDropdownMenu = () => {
        setDropdownMenu(!dropdownMenu);
    };

    useEffect(() => {
        let resizeWindowListener: any = window.addEventListener('resize', () => {
            setIsMobile(window.innerWidth < 991);
        });

        return () => window.removeEventListener('resize', resizeWindowListener);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current?.contains(event.target)) {
                setDropdownMenu(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <div className="bg-header">
            <Container>
                <div className="header">
                    <div
                        className="menu"
                        style={{ display: isMobile ? 'flex' : 'none' }}
                        onClick={toggleHeaderNavMobile}
                    >
                        {headerNavMobileOpen ? <ClearIcon /> : <MenuIcon />}
                    </div>

                    {(!headerNavMobileOpen || window.innerWidth > 576) && (
                        <Link to="/" className="logo">
                            <img src={require('../../assets/images/home/logo.png').default} alt="Logo" />{' '}
                        </Link>
                    )}
                    <div className="search-bar-container">
                        <SearchHeader />
                    </div>
                    <div className="nav-wrapper">
                        {menuItemDesktop.map((item, key) => (
                            <div className="nav-wrapper__item" key={key.toString() + item}>
                                <Link className="item" to={item.path}>
                                    {item.name}
                                </Link>
                                {item.name === 'Marketplace' && <div className="coming-soon">Coming Soon</div>}
                            </div>
                        ))}
                    </div>
                    <div className="wallet-container">
                        {/* <div className="nav-wrapper">
                        <Link className="item" to="/inventory">
                            Inventory
                        </Link>
                    </div> */}
                        {/* {accountActive ? (
                        <select
                            onChange={(e) => onChangeChain(parseInt(e.target.value))}
                            className="select-network"
                        >
                            {SUPPORTED_CHAINID.map((value: number) => (
                                <option selected={value === wallet.chainId} value={value} key={value}>
                                    {SUPPORTED_NETWORKS[value]?.chainName || ''}
                                </option>
                            ))}
                        </select>
                    ) : null} */}
                        {/* {!accountActive ? null : (
                        <div onClick={() => disconnect()} className="account-container">
                            {compactAddress(accountActive)}
                        </div>
                    )} */}
                        {!authSelector?.currentUser?.id ? (
                            <div>
                                {(!headerNavMobileOpen || window.innerWidth > 576) && (
                                    <div
                                        onClick={() => onMoveAnimation('authenticate-form', 'moveInOpacity')}
                                        className="btn-primary"
                                    >
                                        GET IN
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                {(!headerNavMobileOpen || window.innerWidth > 576) && (
                                    <div className="account-img" onClick={toggleDropdownMenu} ref={ref}>
                                        <img src={DefaultAvatar} alt="" />
                                        <div className="account-img__dropdown">
                                            <div
                                                className={
                                                    dropdownMenu
                                                        ? 'account-img__dropdown--container dropdown-open'
                                                        : 'account-img__dropdown--container'
                                                }
                                            >
                                                <Link to="/profile" className="nav-drop">
                                                    Profile
                                                </Link>
                                                <div onClick={handleLogOut} className="nav-drop">
                                                    Logout
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </Container>
            {pathname === '/' && (
                <div className="eap-msg msg-primary">
                    Bought an NFT on our old site? Click{' '}
                    <a href="https://beta.myhotdrops.com/" target="_blank" rel="noreferrer">
                        here
                    </a>{' '}
                    to access them!
                </div>
            )}
            {isWrongNetwork && !headerNavMobileOpen && (
                <div className="eap-msg msg-error">
                    You are on the wrong network. You can change{' '}
                    <span
                        onClick={() => changeNetwork(process.env.REACT_APP_IS_PROD ? ChainId.MAINNET : ChainId.RINKEBY)}
                    >
                        here
                    </span>
                    .
                </div>
            )}
            {pathname === '/eap' && !headerNavMobileOpen && (
                <div className="eap-msg msg-notice">Get 10% off all NFT purchases for 1 year</div>
            )}
            <div className={headerNavMobileOpen ? 'header-mobile-dropdown active' : 'header-mobile-dropdown'}>
                <div className="overlay" onClick={toggleHeaderNavMobile} />
                <div className="list-menu-container">
                    {!authSelector?.currentUser?.id ? (
                        <div className="list-menu">
                            <Link to="/" className="list-menu__item" onClick={toggleHeaderNavMobile}>
                                Home
                            </Link>
                            <Link to="/marketplace" className="list-menu__item" onClick={toggleHeaderNavMobile}>
                                Marketplace
                                <div className="coming-soon">Coming Soon</div>
                            </Link>
                            <Link to="/creators" className="list-menu__item" onClick={toggleHeaderNavMobile}>
                                Creators
                            </Link>
                            <Link to="/how-it-works" className="list-menu__item" onClick={toggleHeaderNavMobile}>
                                How it works
                            </Link>
                        </div>
                    ) : (
                        <div className="list-menu">
                            <Link
                                to={`/profile?tab=${TAB_PROFILE.collected}`}
                                className="list-menu__item"
                                onClick={toggleHeaderNavMobile}
                            >
                                Collected
                            </Link>
                            <Link
                                to={`/profile?tab=${TAB_PROFILE.wishlist}`}
                                className="list-menu__item"
                                onClick={toggleHeaderNavMobile}
                            >
                                Wishlist
                            </Link>
                            <Link to="/marketplace" className="list-menu__item" onClick={toggleHeaderNavMobile}>
                                Marketplace
                                <div className="coming-soon">Coming Soon</div>
                            </Link>
                            <Link to="/creators" className="list-menu__item" onClick={toggleHeaderNavMobile}>
                                Creators
                            </Link>
                            <Link to="/how-it-works" className="list-menu__item" onClick={toggleHeaderNavMobile}>
                                How it works
                            </Link>
                            <div className="list-menu__item" onClick={toggleHeaderNavMobile}>
                                Help
                            </div>
                            <Link to="/profile" className="list-menu__item" onClick={toggleHeaderNavMobile}>
                                Account
                            </Link>
                        </div>
                    )}
                    <div className="list-menu-icon">
                        <a href="https://twitter.com/myhotdrops">
                            <FontAwesomeIcon icon={faTwitter as IconProp} className="icon" />
                        </a>
                        <a href="https://www.instagram.com/myhotdropsnft/">
                            <FontAwesomeIcon icon={faInstagram as IconProp} className="icon" />
                        </a>

                        <FontAwesomeIcon icon={faTiktok as IconProp} className="icon" />
                        <FontAwesomeIcon icon={faDiscord as IconProp} className="icon" />
                        <FontAwesomeIcon icon={faTwitch as IconProp} className="icon" />
                        <FontAwesomeIcon icon={faReddit as IconProp} className="icon" />
                    </div>
                </div>
            </div>
        </div>
    );
}
