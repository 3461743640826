import React from 'react';
import './styles.scss';
import { Container } from 'src/components';
import { Creator } from '../../../../types/creators';

type Props = {
    creator: Creator;
};

export function Bio({ creator }: Props) {
    return (
        <div className="bio">
            <Container>
                <>
                    <h2>STATS</h2>
                    <div className="bio__row-stats">
                        <div className="stats-item">
                            <h5>Drops</h5>
                            <p>-</p>
                        </div>
                        <div className="stats-item">
                            <h5>Fans</h5>
                            <p>{creator?.followers || '-'}</p>
                        </div>
                        <div className="stats-item">
                            <h5>Highest Resale</h5>
                            <p>-</p>
                        </div>
                        <div className="stats-item">
                            <h5># for Resale</h5>
                            <p>-</p>
                        </div>
                    </div>

                    <h2>BIO</h2>
                    <p className="bio__text" dangerouslySetInnerHTML={{__html: creator?.bio}} />
                </>
            </Container>
        </div>
    );
}
