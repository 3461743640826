import { combineReducers } from 'redux';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import ProfileReducer from 'src/pages/Profile/slice';
import HomeReducer from 'src/pages/Home/slice';
import TransactionReducer from 'src/app/transaction/reducer';
import { netMiddleware } from 'src/app/middlewares/net';
import AuthReducer from '../components/AuthenticateModal/slice';

const reducers = combineReducers({
    profile: ProfileReducer,
    home: HomeReducer,
    auth: AuthReducer,
    transaction: TransactionReducer,
});

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: false,
        }).concat(netMiddleware);
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
