import React from 'react';
import './styles.scss';

type IProps = {
    image: string;
    price: string;
};
export function DropCard({ price, image }: IProps) {
    return (
        <div className="card">
            <div className="card__top">
                {/* <img src={require(str).default} alt="dropsImage" /> */}
                <img src={require(`../../assets/images/home/${image}.png`).default} alt="dropsImage" />
            </div>
            <div className="card__bottom">
                <span>USD ${price}</span>
            </div>
        </div>
    );
}
