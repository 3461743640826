import { Creator } from 'src/types/creators';
import { baseRequest } from '../base-request';

export default {
    fetchCreators: async (query?: string): Promise<Creator[]> => {
        let url = query ? `/api/v1/creator?query=${query}` : '/api/v1/creator';
        const {
            data: { data: res },
        } = await baseRequest('get', url);
        return res;
    },
    fetchCreatorByUserName: async (userName: string): Promise<Creator> => {
        let url = `/api/v1/creator/usrname/${userName}`;
        const {
            data: { data: res },
        } = await baseRequest('get', url);
        return res;
    },
    favoriteCreator: async (data: any) => {
        let url = '/api/v1/users/favorite';
        const res = await baseRequest('post', url, data);
        return res;
    },
    unfavoriteCreator: async (data: any) => {
        let url = '/api/v1/users/favorite';
        const res = await baseRequest('delete', url, data);
        return res;
    },
    subscribeCreator: async (data: any) => {
        let url = '/api/v1/users/subscribe';
        const res = await baseRequest('post', url, data);
        return res;
    },
    unsubscribeCreator: async (data: any) => {
        let url = '/api/v1/users/subscribe';
        const res = await baseRequest('delete', url, data);
        return res;
    },
    nftsListCreator: async (id: string | number) => {
        let url = `/api/v1/creator/nfts/${id}`;
        const res = await baseRequest('get', url);
        return res;
    }
};
