import { useWeb3React } from '@web3-react/core';
import { cloneDeep } from 'lodash';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { ChainId } from 'src/configs/Connect/Configs.chainId';
import { SUPPORTED_NETWORKS } from 'src/configs/Connect/Configs.networks';
import { WalletSupported } from 'src/types/wallet';
import { useWallet } from 'use-wallet';
import { onMoveAnimation } from './useDevelopUI';

export const useDisconnectWallet = () => {
    const wallet = useWallet();
    const { connector } = useWeb3React();
    return () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        wallet.connector === 'walletconnect' ? (connector as any).close() : wallet.reset();
    };
};

export const useConnectWallet = () => {
    const { connect } = useWallet();

    return async (chain: number, walletName: WalletSupported) => {
        // try {
        //     wallet.connect('injected');
        // } catch (err: any) {
        //     alert(err.message);
        // }
        try {
            if (walletName === 'walletconnect' && window.ethereum) {
                return;
            }
            if (walletName === 'injected' && (!window.ethereum || !window.web3)) {
                toast.error(isMobile ? 'You must use Walletconnect to connect' : 'You have to install metamask');
                return;
            }
            await connect(walletName);
        } catch (err: any) {
            // toast.error(err.message);
        }
    };
};

export const useChangeChain = () => {
    const wallet = useWallet();
    return (chainNum: number) => {
        if ([ChainId.MAINNET, ChainId.RINKEBY].includes(chainNum)) {
            wallet.ethereum?.send('wallet_switchEthereumChain', [
                { chainId: SUPPORTED_NETWORKS[chainNum].chainId },
                wallet.account,
            ]);
        } else {
            const params = cloneDeep(SUPPORTED_NETWORKS[chainNum || 56]);
            delete params.chainNumb;
            delete params.path;
            delete params.pathDisable;
            wallet.ethereum?.send('wallet_addEthereumChain', [params, wallet.account]);
        }
    };
};

export const useListenWallet = () => {
    const wallet = useWallet();
    React.useEffect(() => {
        if (wallet.status === 'connected') {
            onMoveAnimation('connect-modal', 'moveOutOpacity');
        } else if (wallet.status === 'error') {
            // toast.error(wallet.error?.message);
        }
    }, [wallet.status]);
};

export const useCheckAccountActive = () => {
    const wallet = useWallet();
    return wallet.account;
};
