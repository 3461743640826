import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Container } from 'src/components';
import creatorApi from 'src/api/creator';
import { HottiesItem } from 'src/pages/Home/components/FollowHotties/hotties-item';
import { Creator } from 'src/types/creators';

type Props = {
    creatorId: number;
    name: string;
};

export function Friends({ creatorId, name }: Props) {
    const [creators, setCreators] = useState<Creator[]>([]);

    const fetchCreators = async () => {
        const creatorsResponse = await creatorApi.fetchCreators();
        setCreators(creatorsResponse);
    };

    useEffect(() => {
        fetchCreators();
    }, []);

    return (
        <div className="friends">
            <Container>
                <>
                    <h2>{name}&apos;S FRIENDS</h2>
                    <div className="friends__hotties">
                        {creators
                            .filter((creator) => creator.id !== creatorId)
                            .map((creator) => (
                                <HottiesItem
                                    username={creator.username}
                                    name={`${creator.firstName} ${creator.lastName}`}
                                    image={creator.avatar}
                                    key={creator.id}
                                />
                            ))}
                    </div>
                </>
            </Container>
        </div>
    );
}
