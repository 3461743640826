import { Title } from "src/types/footer";

export const NavigateList: Title[] = [
    {
        title: 'Company',
        subtitle: [
            {
                text: 'About us',
                href: '',
            },
            {
                text: 'Create with us',
                href: '',
            },
            {
                text: 'What is an NFT?',
                href: '',
            },
            {
                text: 'Displaying NFTs',
                href: '',
            },
        ],
    },
    {
        title: 'Help',
        subtitle: [
            {
                text: 'FAQ',
                href: '',
            },
            {
                text: 'Report Security Issue',
                href: '',
            },
            {
                text: 'Careers',
                href: '',
            },
            {
                text: 'Help',
                href: '',
            },
        ],
    },
    {
        title: 'Legal',
        subtitle: [
            {
                text: 'Terms of Service',
                href: 'https://beta.myhotdrops.com/terms-of-service-and-use/',
            },
            {
                text: 'Privacy Policy',
                href: 'https://www.iubenda.com/privacy-policy/15957209',
            },
            {
                text: 'GDPR',
                href: 'https://beta.myhotdrops.com/content-removal-request/',
            },
            {
                text: 'CCPA',
                href: 'https://beta.myhotdrops.com/content-removal-request/',
            },
        ],
    },
];
