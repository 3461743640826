import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ProviderRedux } from 'react-redux';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { DataContextProvider } from 'src/context/DataContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ThemeProvider } from '@mui/material/styles';
import { store } from './app/store';
import { theme } from './styles/themes';

const queryClient = new QueryClient();

let persistor = persistStore(store);

export function Providers({ children }: { children: JSX.Element }) {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <ErrorBoundary>
                    <DataContextProvider>
                        <QueryClientProvider client={queryClient}>
                            <ProviderRedux store={store}>
                                <PersistGate loading={null} persistor={persistor}>
                                    {children}
                                </PersistGate>
                            </ProviderRedux>
                        </QueryClientProvider>
                    </DataContextProvider>
                </ErrorBoundary>
            </Router>
        </ThemeProvider>
    );
}
