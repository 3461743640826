import { Box, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import React, { useState } from 'react';
import { AppButton } from 'src/components/Base/AppButton';
import { AppInput } from 'src/components/Base/AppInput';

const oneDay = 3600 * 1000 * 24;

const filterOptions = [
    {
        id: 0,
        name: 'All Time',
        value: '',
    },
    {
        id: 1,
        name: 'Last 24h',
        value: oneDay,
    },
    {
        id: 2,
        name: 'Last Week',
        value: oneDay * 7,
    },
    {
        id: 3,
        name: 'Last 28 Days',
        value: oneDay * 28,
    },
    {
        id: 4,
        name: 'Last Year',
        value: oneDay * 365,
    },
];

const initAction = [
    {
        id: 1,
        value: 'Buy NFT',
    },
    {
        id: 2,
        value: 'Put Sale',
    },
    {
        id: 3,
        value: 'Buy Badges',
    },
];

function Example() {
    const [file, setFile] = useState<Array<any>>();
    const [filter, setFilter] = useState<string>('');
    const [actionTx, setActionTx] = useState('Buy NFT');
    // const [array, setArray] = useState([]);

    const [resultFilter, setResultFilter] = useState(0);

    function csvToArray(str: string) {
        const headers = str
            .slice(0, str.indexOf('\n'))
            .split(',')
            .map((header: string) => header.replaceAll('"', '').trim());
        const rows = str.slice(str.indexOf('\n') + 1).split('\n');
        const arr = rows.map((row: any) => {
            const values = row.split(',').map((d: string) => d.replaceAll('"', '').trim());
            const el = headers.reduce((object: any, header: string, index) => {
                // eslint-disable-next-line no-param-reassign
                object[header] = values[index];
                return object;
            }, {});
            return el;
        });
        // return the array
        return arr;
    }

    const removeItemDupplicate = (arr: any) => {
        const cloneArr = [...arr];
        cloneArr.map((item: any, index: number) => {
            return cloneArr.slice(index + 1)?.map((e: any, i: number) => {
                if (item?.From && e?.From && item.From === e.From) {
                    return cloneArr.splice(i + 1, 1, undefined);
                }
                return null;
            });
        });

        return cloneArr.filter((item) => item);
    };

    const handleUpload = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = (e.target as any).files[0];
        const reader = new FileReader();

        reader.onload = (event: any) => {
            let data = event.target.result;
            data = csvToArray(data);
            setFile(data);
        };
        reader.readAsText(value);
    };

    const handleConfirm = () => {
        const arr = file
            ?.filter((item: any) => {
                const timeStamp = new Date().getTime() - item.UnixTimestamp * 1000 < Number(filter);
                const action = item.Method === actionTx;
                const status = item.Status === '';

                return timeStamp && action && status;
            })
            .sort((a, b) => a.UnixTimestamp * 1 - b.UnixTimestamp * 1);
        if (!arr) return;
        const result = removeItemDupplicate(arr);
        console.log(result);
        // setArray(result as any);
        setResultFilter(result.length);
    };

    return (
        <Box sx={{ p: '40px' }}>
            <RadioGroup onChange={(e) => setFilter(e.target.value)}>
                {filterOptions.map((item) => {
                    return (
                        <FormControlLabel
                            key={item.id}
                            value={item.value}
                            sx={{
                                '.MuiFormControlLabel-label': {
                                    color: 'white !important',
                                },
                            }}
                            control={<Radio />}
                            label={item.name}
                        />
                    );
                })}
            </RadioGroup>
            <Box sx={{ m: '40px 0' }}>
                <AppInput variant="normal" type="file" id="csvFile" onChange={handleUpload} />
            </Box>
            <FormControl fullWidth>
                <Box sx={{ fontWeight: 700 }}>Event</Box>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={actionTx}
                    label="Age"
                    onChange={(e) => setActionTx(e.target.value)}
                    sx={{
                        '.MuiInputBase-input': {
                            color: 'white !important',
                        },
                    }}
                >
                    {initAction.map((item) => {
                        return (
                            <MenuItem key={item.id} value={item.value}>
                                {item.value}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <AppButton color="white" onClick={handleConfirm} sx={{ mt: '20px' }}>
                Filter
            </AppButton>
            <Box
                sx={{
                    mt: '20px',
                    fontSize: '20px',
                    fontWeight: 700,
                }}
            >
                {`Results: ${resultFilter}`}
            </Box>
        </Box>
    );
}

export default Example;
