import React from 'react';
import { DropCard } from 'src/components';
import { DropList } from './hard';
import './styles.scss';

export function Drops() {
    return (
        <div className="bg-drop">
            <div className="drops-container">
                <div className="drops-container__header">
                    <span className="title">Drops</span>
                    <div className="comming-soon">COMING SOON</div>
                </div>

                <div className="drops-container__list">
                    {DropList.map((item, key) => (
                        <DropCard price={item.price} image={item.image} key={key.toString() + item} />
                    ))}
                </div>
            </div>
        </div>
    );
}
