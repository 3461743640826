import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormHelperText, Tooltip, useTheme } from '@mui/material';
import { BigNumber as BN } from 'bignumber.js';
import { BigNumber } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'src/app/store';
import { transactionActions } from 'src/app/transaction/reducer';
import musicIcon from 'src/assets/icons/music-icon.svg';
import videoIcon from 'src/assets/icons/video-icon.svg';
import usdtTokenImage from 'src/assets/images/token/usdt.svg';
import { AppButton, AppInputAmount, AppModal } from 'src/components';
import ERC20_ABI from 'src/constants/abis/erc20-abi.json';
import HOTDROPS_ABI from 'src/constants/abis/hotdrops-abi.json';
import { EAP_TOKEN_ADDRESS, HOT_DROPS_CONTRACT } from 'src/constants/addresses';
import { DEFAULT_CHAIN_ID } from 'src/constants/chainId';
import useContract, { useChain, useContractWithProvider } from 'src/services/hooks/useContract';
import { onMoveAnimation } from 'src/services/hooks/useDevelopUI';
import { formatBalance } from 'src/services/utils/format';
import { INft } from 'src/types/nfts';
import { useWallet } from 'use-wallet';
import * as yup from 'yup';

interface PropsModal {
    isOpen: boolean;
    handleClose: () => void;
    modalData: INft;
    creator: {
        firstName: string;
        lastName: string;
        username: string;
    };
}

interface IForm {
    quantity: string;
    discountCode: string;
}

function ModalBuyNft({ isOpen, handleClose, modalData, creator }: PropsModal) {
    const theme = useTheme();
    const { account } = useWallet();
    const chainId = useChain();
    const transactionState = useSelector((state: RootState) => state.transaction);
    const dispatch = useDispatch();

    const [nftDetails, setNftDetails] = useState<INft>();

    const tokenContract = useContract(EAP_TOKEN_ADDRESS[chainId], ERC20_ABI);
    const hotdropsContract = useContractWithProvider(HOT_DROPS_CONTRACT[chainId], HOTDROPS_ABI);

    const {
        handleSubmit,
        setValue,
        watch,
        register,
        formState: { errors },
    } = useForm<IForm>({
        mode: 'onSubmit',
        defaultValues: {
            quantity: '',
            discountCode: '',
        },
        resolver: yupResolver(
            yup.object().shape({
                quantity: yup
                    .string()
                    .required('This field is required')
                    .test({
                        test: (val) => {
                            return Number(val) > 0;
                        },
                        message: 'Quantity must be greater than 0',
                    })
                    .test({
                        test: (val) => {
                            return new BN(val || '0').lte(nftDetails?.orders?.quantity?.toString() || '0');
                        },
                        message: `Quantity must be less than or equal to ${
                            nftDetails?.orders?.quantity?.toString() || '0'
                        }`,
                    }),
            }),
        ),
    });

    useEffect(() => {
        (async () => {
            if (!tokenContract) return;
            const allowance = await tokenContract.allowance(account, HOT_DROPS_CONTRACT[chainId || DEFAULT_CHAIN_ID]);
            if (
                BigNumber.from(allowance).lt(
                    BigNumber.from(watch('quantity') || '0').mul(nftDetails?.orders?.price || '0'),
                )
            ) {
                dispatch(
                    transactionActions.updateApproveStatus({
                        approveStatus: 'Not Approved',
                        token: EAP_TOKEN_ADDRESS[chainId || DEFAULT_CHAIN_ID],
                    }),
                );
            } else {
                dispatch(
                    transactionActions.updateApproveStatus({
                        approveStatus: 'Approved',
                        token: EAP_TOKEN_ADDRESS[chainId || DEFAULT_CHAIN_ID],
                    }),
                );
            }
        })();
    }, [dispatch, nftDetails?.orders?.price, watch('quantity')]);

    const handleApprove = async (data: IForm) => {
        dispatch(
            transactionActions.approveToken({
                spender: HOT_DROPS_CONTRACT[chainId || DEFAULT_CHAIN_ID],
                token: EAP_TOKEN_ADDRESS[chainId || DEFAULT_CHAIN_ID],
                value: BigNumber.from(nftDetails?.orders?.price).mul(data.quantity).toString(),
            }),
        );
    };

    const handleBuyNft = async (data: IForm) => {
        if (!nftDetails?.orderId || !tokenContract || !data.quantity) return;

        const balance = await tokenContract.balanceOf(account);
        const amount = BigNumber.from(nftDetails?.orders?.price).mul(data.quantity);
        if (BigNumber.from(balance).lt(amount)) {
            return toast.error("You don't have enough balance to execute the transaction.", {
                position: 'top-center',
            });
        }
        dispatch(
            transactionActions.handleBuyNft({
                orderIndex: nftDetails.orderId?.toString(),
                quantity: data.quantity,
                disCode: data.discountCode || '0',
                callback: () => handleClose(),
            }),
        );
    };

    useEffect(() => {
        if (transactionState.callback) {
            transactionState.callback();
            dispatch(transactionActions.cancelCallback());
        }
    }, [transactionState.callback, dispatch]);

    useEffect(() => {
        (async () => {
            let orders;
            if (hotdropsContract?.address && modalData?.orderId) {
                orders = await hotdropsContract?.orders(modalData?.orderId);
                orders = {
                    owner: orders.owner,
                    price: orders.price,
                    quantity: orders.quantity,
                    tokenId: orders.tokenId,
                };
            }

            setNftDetails({
                ...nftDetails,
                ...modalData,
                orders,
            });
        })();
    }, [hotdropsContract?.address, modalData?.orderId]);

    return (
        <AppModal open={isOpen} onClose={handleClose} title="Buy NFT">
            <form
                onSubmit={handleSubmit((data: IForm) => {
                    if (!account) {
                        return onMoveAnimation('connect-modal', 'moveInOpacity');
                    }
                    if (transactionState.approval?.approveStatus === 'Not Approved') {
                        handleApprove(data);
                    } else {
                        handleBuyNft(data);
                    }
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column',
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            className="creator-nfts__nfts-item-child"
                            sx={{
                                backgroundImage: `url(${nftDetails?.cover || nftDetails?.metaData})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        >
                            {(nftDetails?.fileExtension === 'mp3' || nftDetails?.fileExtension === 'mp4') && (
                                <Box
                                    sx={{
                                        display: 'inline-block',
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '15px',
                                        background:
                                            'linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(22, 22, 26, 0.7) 95%)',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundImage: `url(${
                                                nftDetails?.fileExtension === 'mp3' ? musicIcon : videoIcon
                                            })`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            position: 'absolute',
                                            width: '25px',
                                            height: '25px',
                                            border: `1px solid ${theme.color.textWhite}`,
                                            borderRadius: '8px',
                                            mt: '10px',
                                            ml: '10px',
                                        }}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '300px',
                            ml: '20px',
                            [theme.breakpoints.down('md')]: {
                                ml: '0',
                                mt: '20px',
                            },
                        }}
                    >
                        <Tooltip title={nftDetails?.name as string}>
                            <Box
                                sx={{
                                    fontSize: '32px',
                                    fontWeight: 700,
                                    lineHeight: '150%',
                                    maxWidth: '100%',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {nftDetails?.name}
                            </Box>
                        </Tooltip>
                        <Tooltip title={`${creator?.firstName} ${creator?.lastName}`}>
                            <Box
                                sx={{
                                    background: theme.color.bgGradient,
                                    backgroundClip: 'text',
                                    textFillColor: 'transparent',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    maxWidth: '100%',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {`${creator?.firstName} ${creator?.lastName}`}
                            </Box>
                        </Tooltip>
                        <Box
                            sx={{
                                fontSize: '14px',
                                color: theme.color.text6,
                                mt: '16px',
                            }}
                        >
                            <Box
                                sx={{
                                    background: theme.color.bgGradient,
                                    backgroundClip: 'text',
                                    textFillColor: 'transparent',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    fontWeight: 700,
                                }}
                                component="span"
                            >
                                {nftDetails?.orders?.quantity ? nftDetails?.orders?.quantity?.toString() : '-'}
                            </Box>{' '}
                            Copies available
                        </Box>
                        <Box
                            sx={{
                                lineHeight: '150%',
                                mt: '16px',
                                maxWidth: '100%',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <Box sx={{ color: theme.color.text6, fontSize: '14px' }} component="span">
                                Original price:
                            </Box>
                            <Tooltip
                                title={`${
                                    nftDetails?.orders?.quantity ? formatBalance(nftDetails?.orders?.price) : '-'
                                } USDT`}
                            >
                                <Box
                                    sx={{
                                        background: theme.color.bgGradient,
                                        backgroundClip: 'text',
                                        textFillColor: 'transparent',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        fontSize: '24px',
                                        fontWeight: 700,
                                        ml: '8px',
                                    }}
                                    component="span"
                                >
                                    {`${
                                        nftDetails?.orders?.quantity ? formatBalance(nftDetails?.orders?.price) : '-'
                                    } USDT`}
                                </Box>
                            </Tooltip>
                        </Box>
                        <Box sx={{ mt: '18px' }}>
                            <AppInputAmount
                                label="Enter NFT quantity"
                                {...register('quantity')}
                                isAllowed={({ value }) => {
                                    return value?.split('.')[0].length <= 5;
                                }}
                                onValueChange={({ value }) => setValue('quantity', value)}
                            />
                            <FormHelperText
                                sx={{
                                    color: theme.color.error,
                                    fontSize: 12,
                                    lineHeight: '150%',
                                    mt: 0,
                                }}
                            >
                                {errors?.quantity?.message}
                            </FormHelperText>
                            <AppInputAmount
                                {...register('discountCode')}
                                label="Discount Code"
                                sxBox={{ mt: '18px' }}
                                onValueChange={({ value }) => setValue('discountCode', value)}
                            />
                            <AppInputAmount
                                name="totalAmount"
                                label="Total amount"
                                disabled
                                sxBox={{ mt: '16px' }}
                                endAdornment={
                                    <Box sx={{ display: 'flex', alignItems: 'center', paddingRight: '10px' }}>
                                        <img src={usdtTokenImage} alt="" height={24} width={24} />
                                    </Box>
                                }
                                value={new BN(formatBalance(nftDetails?.orders?.price || '0'))
                                    .times(watch('quantity') || '0')
                                    .toFixed()}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ borderBottom: `1px solid ${theme.color.border}`, opacity: 0.5, m: '32px 0' }} />
                <Box display="flex" justifyContent="center">
                    {transactionState.approval?.approveStatus === 'Approved' ? (
                        <AppButton
                            type="submit"
                            sx={{ m: '0 8px', borderRadius: '50px' }}
                            loading={transactionState.isPending}
                        >
                            Buy
                        </AppButton>
                    ) : (
                        <AppButton
                            type="submit"
                            sx={{ m: '0 8px', borderRadius: '50px' }}
                            disabled={transactionState.isPending}
                        >
                            {transactionState.approval?.approveStatus === 'Approving' ? 'Approving...' : 'Approve'}
                        </AppButton>
                    )}
                    <AppButton
                        type="button"
                        sx={{ m: '0 8px', borderRadius: '50px' }}
                        color="gradient"
                        onClick={handleClose}
                    >
                        Cancel
                    </AppButton>
                </Box>
            </form>
        </AppModal>
    );
}

export default ModalBuyNft;
