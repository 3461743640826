/* eslint-disable no-plusplus */
import { ERROR_CODE } from 'src/constants/error_code';
import { getAddress } from '@ethersproject/address';
import _ from 'lodash';
import { toast } from 'react-toastify';

export const compactAddress = (address: string): string => {
    if (address && typeof address === 'string') {
        return `${address.substring(0, 4)}...${address.substring(address.length - 4, address.length)}`;
    }
    return '...';
};

export const onFormatDateHuman = (date: string) => {
    console.log(date);
    const eleDate = new Date(date);
    return eleDate.toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });
};

export const formatPathToHuman = (path: string): string => {
    return path.slice(1);
};
export const onShowError = (errId: number): string => {
    let error = '';
    for (let attr in ERROR_CODE) {
        if (ERROR_CODE[attr] === errId) {
            toast.error(attr);
            error = attr;
        }
    }
    return error;
};

export const toHumanName = (data: any) => {
    if (data.name) {
        return data.name;
    }
    if (data.username) {
        return data.username;
    }
    if (data.first_name || data.last_name) {
        return `${data.first_name} ${data.last_name}`;
    }
    if (data.email) {
        return data.email;
    }
    return 'Default';
};

export const toHumanText = (name: string) => {
    let arrName = _.split(name, '-');
    let returnName = '';
    for (let i = 0; i < arrName.length; i++) {
        returnName += ` ${arrName[i]}`;
    }
    return returnName.includes('/') ? returnName.replace('/', '') : returnName;
};

export function isAddress(value: any): string | false {
    try {
        return getAddress(value);
    } catch {
        return false;
    }
}
