/**
 *
 * Asynchronously loads the component
 *
 */

import { lazyLoad } from '../../services/utils/loadable';

export const ProfilePage = lazyLoad(
    () => import('./index'),
    (module) => module.Profile,
);
