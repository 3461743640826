import React from 'react';
import { SubTitle } from 'src/types/footer';
import './styles.scss';

type NavigateModel = {
    title: string;
    subtitle: SubTitle[];
};

export function NavigateItem({ title, subtitle }: NavigateModel) {
    return (
        <div className="navigate-container">
            <span className="title">
                {title}{' '}
                {title === 'Company' || title === 'Help' ? <span className="coming-soon">Coming soon</span> : null}
            </span>
            {subtitle.map((item, key) => (
                <a
                    className="subtitle"
                    key={key.toString() + item}
                    href={item.href}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => !item.href && e.preventDefault()}
                >
                    {item.text}
                </a>
            ))}
        </div>
    );
}
