/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable no-param-reassign */
// we will use this file later
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { onShowError } from 'src/services/utils/utils';

import { RootState } from '../../app/store';
import { InitialStatetype } from './types';

const initialState: InitialStatetype = {
    data: [],
    count: 1,
};

export const postContact = createAsyncThunk('Home/postContact', async (data: { info: any; setInfo: any }) => {
    const response = await fetch(`${process.env.REACT_APP_API || ''}/api/v1/contact/submit`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify(data.info),
    }).then((res) => res.json());
    // The value we return becomes the `fulfilled` action payload
    if (response.error_code !== 0) {
        onShowError(response.error_code);
        throw 'Somthing went wrong';
    }
    data.setInfo({
        email: '',
        web: '',
        onlyfan: '',
    });
    return response;
});

export const HomeSlice = createSlice({
    name: 'Home',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        incrementPage: (state, action) => {
            state.data = {
                data: [...action.payload],
            };
        },
    },
    extraReducers: (builder) => {
        builder
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .addCase(postContact.pending, () => {
                // console.log('pending');
            })
            .addCase(postContact.fulfilled, () => {
                // console.log('fulfilled', action);
                toast.success('Contact has been recorded');
            })
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .addCase(postContact.rejected, () => {
                // console.log('rejected');
            });
    },
});

export const { incrementPage } = HomeSlice.actions;

export const selectData = (state: RootState) => state.home.data;

export default HomeSlice.reducer;
