import React from 'react';
import './styles.scss';
import { ArrowForwardIos } from '@material-ui/icons';

type TrendingProp = {
    image: string;
    name: string;
};

export function TrendingCard({ name, image }: TrendingProp) {
    return (
        <div className="container">
            <img
                src={require(`../../../../assets/images/home/${image}.png`).default}
                alt="avatar"
                className="card-image"
            />

            <div className="card-name">
                <img src={require('../../../../assets/images/home/trending-logo.png').default} alt="" className="watshot-icon" />
                {name}
                <ArrowForwardIos className="forward-icon" />
            </div>
        </div>
    );
}
